import { NA } from "@/utils/convert";
import { dateStrToMsec } from "@/utils/time";
import { Tooltip } from "react-tooltip";

const FairValue = ({ data }) => {
  const getPast3mTs = () => {
    const now = new Date();
    now.setMonth(now.getMonth() - 3);
    return now.getTime();
  };

  const past3mTs = getPast3mTs();
  const past3mTx = data.transactions.filter(t => dateStrToMsec(t.saleDate) >= past3mTs);
  const avgPsf3m = past3mTx.length > 0
    ? past3mTx.map(t => t.price / t.area).reduce((s, t) => s + t, 0) / past3mTx.length
    : null;

  const fairPsf = data.fairPsf && Object.keys(data.fairPsf).length > 0
    ? Object.keys(data.fairPsf).reduce((s, t) => s + data.fairPsf[t], 0) / Object.keys(data.fairPsf).length
    : null;
  const underPriced = avgPsf3m < fairPsf;
  
  const color = fairPsf ? (underPriced ? 'bg-green-2' : 'bg-red-1') : 'bg-dark';
  const textColor = fairPsf ? (underPriced ? 'text-green-2' : 'text-red-1') : 'text-dark';

  return (
    <div className="mt-20 mb-20">
      <div className="d-flex items-center">
        <div className={`flex-center ${color} rounded-4 size-70 text-22 fw-800 text-white`}>
          <i className="icon-usd text-white-1" />
        </div>

        <div className="ml-15 y-gap-5">
          <div className="text-12 text-light-1 lh-12">Estimated Fair Price</div>
          <div
            className="text-14 text-dark-1 fw-500 lh-14"
            data-tooltip-id="proj-review-tooltip"
            data-tooltip-html="Fair price relative to nearby properties"
            data-tooltip-variant="dark"
            data-tooltip-place="bottom"
          >Fair Price: {fairPsf !== null && fairPsf >= 0 ? `$${fairPsf.toFixed(2)} psf` : 'No data yet'}</div>
          <div
            className={`text-14 lh-14 fw-500`}
            data-tooltip-id="proj-review-tooltip"
            data-tooltip-html="Past 3 months average PSF"
            data-tooltip-variant="dark"
            data-tooltip-place="bottom"
          >Recent PSF: <span className={textColor}>{avgPsf3m ? `$${avgPsf3m.toFixed(2)}` : NA}</span></div>
        </div>
      </div>

      <Tooltip id="proj-review-tooltip" style={{ zIndex: 600 }} />
    </div>
  );
};

export default FairValue;
