import { useState, useEffect, useRef } from "react";
import {
  useNavigate,
  useLocation,
  useSearchParams
} from "react-router-dom";

import DefaultHeader from "@/components/header/default-header";
import MetaComponent from "@/components/common/MetaComponent";
import Loader from "@/components/common/Loader";
import ErrorContent from "@/components/mapv2/error/ErrorContent";
import {
  getHomePropertyMediaStyle,
  getHomeMapMediaStyle,
  continueIfAllowedUser,
  noSelectClass
} from "@/utils/user";
import MainMap from "@/components/mapv2/MainMap";
import {
  getFile,
  getJsonFile
} from "@/utils/api";
import {
  MAP_FILTER_OPTIONS,
  decompressAdvMapData,
  decompressBasicMapData,
  decompressSingleCompData,
  getCompatNameAsId,
  getProjectKey,
  getProjectLabel,
  getPropertyFileName
} from "@/utils/map";
import FilterBox from "@/components/mapv2/search/FilterBox";
import { convertMapUrl } from "@/utils/url";
import PropertyView from "@/components/mapv2/PropertyView";
import {
  LOCATION_AREA,
  LOCATION_BLOCK,
  LOCATION_COMPARE,
  LOCATION_MARKER,
  LOCATION_PROPERTY,
  LOCATION_SCHOOL,
  LOCATION_STATION,
  LOCATION_UPCOMING,
  getBlockUnitFromAddress
} from "@/utils/areas";
import { initializeFilters } from "@/utils/filter";
import AreaView from "@/components/mapv2/AreaView";
import AmenityView from "@/components/mapv2/AmenityView";
import CompareProView from "@/components/mapv2/CompareProView";
import UpcomingView from "@/components/mapv2/UpcomingView";
import { capitalizeAll } from "@/utils/text";

const DEFAULT_METADATA = {
  title: "REALSMART.SG | Search Properties | Supercharge your property search",
  description: "REALSMART.SG - Supercharge your property search",
};

const HomeMap = ({
  user,
  session
}) => {
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const getDefaultTarget = (params) => {
    if (params.get('id')) {
      if (params.get('p')) {
        return {
          id: params.get('id'),
          projectId: params.get('p'),
          type: LOCATION_PROPERTY
        };
      } else {
        return {
          id: params.get('id'),
          type: LOCATION_MARKER
        };
      }
    } else if (params.get('sch')) {
      return {
        id: params.get('sch'),
        type: LOCATION_SCHOOL
      };
    } else if (params.get('stn')) {
      return {
        id: params.get('stn'),
        type: LOCATION_STATION
      };
    } else if (params.get('s')) {
      // this search is to maintain backward compatibility with v1 table pages
      return {
        search: {
          project: params.get('s'),
          street: params.get('t')
        },
        type: 'search'
      };
    } else if (params.get('m')) {
      if (params.get('m') === 'compare') {
        return {
          type: LOCATION_COMPARE
        };
      }
    }
    return null;
  };

  const [metadata, setMetadata] = useState(DEFAULT_METADATA);

  const [target, setTarget] = useState(getDefaultTarget(params)); // actual target to load data
  const [focus, setFocus] = useState(null);                       // zoom in on map only
  const [compareList, setCompareList] = useState({});             // data list to use for compare pro
  
  const [hasSearchResults, setHasSearchResults] = useState(false);

  const [loading, setLoading] = useState(true);
  const [err, setErr] = useState(null);
  const [mapData, setMapData] = useState(null);
  const [hasAdvMapData, setHasAdvMapData] = useState(false);
  const [filterSelected, setFilterSelected] = useState(initializeFilters(MAP_FILTER_OPTIONS, params));

  const [isMinimized, setIsMinimized] = useState(false);
  const [isMaximized, setIsMaximized] = useState(false);
  const isMinimizedRef = useRef(isMinimized);
  const [mapMediaStyle, setMapMediaStyle] = useState(getHomePropertyMediaStyle(target));
  const [screenDim, setScreenDim] = useState({ height: window.innerHeight, width: window.innerWidth });

  // handle URL search parameters to load correct place
  const loadTarget = (target, mapData) => {
    if (target?.type === LOCATION_PROPERTY) {
      const property = mapData.projects.find(p => p.marker === target.id && p.projectId === target.projectId);
      const marker = mapData.markers.find(m => m.name === target.id);
      goToPropertyUsingData(marker, property);
    } else if (target?.type === LOCATION_MARKER) {
      const marker = mapData.markers.find(m => m.name === target.id);
      goToMarkerUsingData(marker);
    } else if (target?.type === LOCATION_SCHOOL) {
      goToSchool(target.id);
    } else if (target?.type === LOCATION_STATION) {
      const stationData = mapData.stations.find(s => s.id === target.id);
      if (stationData) goToStation(stationData);
    } else if (target?.type === 'search') {
      const projects = mapData.projects.filter(p => p.project === target.search.project);
      if (projects.length > 0) {
        // for now just take the first matching project
        const project = projects[0];
        const markers = mapData.markers.filter(m => m.name === project.marker);
        if (markers.length > 0) {
          const marker = markers[0];
          goToPropertyUsingData(marker, project);
        }
      }
    }
  };

  // handler for history stack pop
  const handleHistoryPop = () => {
    const currentUrl = new URL(window.location.href);
    const params = currentUrl.searchParams;
    const target = getDefaultTarget(params);
    loadTarget(target, mapData);
  };

  /* Load map */
  const loadMapData = () => {
    setErr(null);

    getFile('m', 'e', txt => {
      // set the default map data
      const mapData = decompressBasicMapData(txt);
      setMapData(mapData);

      // handle url if query parameters specified
      loadTarget(target, mapData);

      setLoading(false);
      
      // set listener for history stack pop action
      window.addEventListener('popstate', handleHistoryPop);

      // start fetching advance map data in background
      loadAdvMapData();
    }, err => {
      setLoading(false);
      setErr('Failed to load map');
    });
  };

  const loadAdvMapData = (callback) => {
    getFile('m', 'b', txt => {
      const data = decompressAdvMapData(txt);
      const newMapData = { ...mapData };
      data.forEach(r => {
        const idx = newMapData.projectIndex[getProjectKey(r)];
        if (idx === undefined) return;  // skip projects not found
        newMapData.projects[idx] = {
          ...newMapData.projects[idx],
          ...r
        };
      });
      setMapData(newMapData);
      setHasAdvMapData(true);
      callback(newMapData.projects);
    });
  };

  useEffect(() => {
    continueIfAllowedUser(user, () => {
      // do nothing
    }, navigate, pathname);
  }, [user]);

  /**
   * Load map data right at the start - if the map data can be cached and user is a guest
   * user will revisit map with the cached map data anyway
   */
  useEffect(() => {
    // load map data right at the start
    if (!mapData) loadMapData();

    // clean up on unmount of component
    return () => {
      window.removeEventListener('popstate', handleHistoryPop);
    };
  }, []);

  /* Handle screen sizing */
  useEffect(() => { isMinimizedRef.current = isMinimized }, [isMinimized]);

  const handleResize = () => {
    if (!isMinimizedRef.current) setMapMediaStyle(getHomePropertyMediaStyle(target));
    setScreenDim({ height: window.innerHeight, width: window.innerWidth });
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  /* Handle property window */
  const handleMinimize = () => {
    setMapMediaStyle(getHomeMapMediaStyle());
    setIsMinimized(true);
    setIsMaximized(false);
  };

  const handleDefault = () => {
    setMapMediaStyle(getHomePropertyMediaStyle(target));
    setIsMinimized(false);
    setIsMaximized(false);
  };

  const handleMaximize = () => {
    setIsMaximized(true);
    setIsMinimized(false);
  };

  const closePropertyDetails = () => {
    setTarget(null);
    setFocus(null);
    setMapMediaStyle(getHomeMapMediaStyle());
    convertMapUrl(null);
  }

  /* Map interaction */
  const goToPropertyUsingData = (marker, property) => {
    if (!property || !marker) return;
    const markerName = property.marker;
    const projectId = property.projectId;

    // set new target
    const newTarget = {
      ...property,
      id: markerName,
      projectId,
      markerData: marker,
      type: LOCATION_PROPERTY
    };
    setTarget(newTarget);

    // set UI layout
    setMapMediaStyle(getHomePropertyMediaStyle(newTarget));
    if (isMaximized || isMinimized) handleDefault();

    // set focus target on map
    setFocus({
      target: { ...newTarget }
    });

    // update page URL
    convertMapUrl(newTarget);
  };

  const goToProperty = (markerName, projectId) => {

    // do nothing if already selected this property
    if (target?.type === LOCATION_PROPERTY && markerName === target?.id && projectId === target?.projectId) return;

    // find property
    const property = mapData.projects.find(p => p.marker === markerName && p.projectId === projectId);
    if (!property) return;

    // find marker
    const marker = mapData.markers.find(m => m.name === markerName);
    if (!marker) return;

    goToPropertyUsingData(marker, property);
  };

  const goToMarkerUsingData = (marker) => {
    if (!marker) return;
    const markerName = marker.name;

    // check if only 1 property in marker
    // if so it means that no need to view marker, just jump straight to property
    if (marker.properties.length > 1) {
      // set new target
      const newTarget = {
        ...marker,
        id: markerName,
        projects: marker.properties,
        isMarker: true,
        type: LOCATION_MARKER
      };
      setTarget(newTarget);

      // set UI layout
      setMapMediaStyle(getHomePropertyMediaStyle(newTarget));
      if (isMaximized || isMinimized) handleDefault();

      // set focus target on map
      setFocus({
        target: { ...newTarget }
      });

      // update page URL
      convertMapUrl(newTarget);
    } else {
      goToPropertyUsingData(marker, marker.properties[0]);
    }
  };

  const goToMarker = (markerName) => {
    // do nothing if already selected this marker
    if (target?.type === LOCATION_MARKER && markerName === target?.id) return;

    // find marker
    const marker = mapData.markers.find(m => m.name === markerName);
    if (!marker) return;

    goToMarkerUsingData(marker);
  };

  const goToBlock = (block) => {
    // set focus target on map
    setFocus({
      target: {
        ...block,
        id: block.address,
        type: LOCATION_BLOCK
      },
      links: [target]
    });
  };

  const goToArea = (area) => {
    // set new target
    const newTarget = {
      ...area,
      id: area.id,
      type: LOCATION_AREA
    };
    setTarget(newTarget);

    // set UI layout
    setMapMediaStyle(getHomePropertyMediaStyle(newTarget));
    if (isMaximized || isMinimized) handleDefault();

    // set focus target on map
    setFocus({
      target: area,
      type: LOCATION_AREA
    });

    // update page URL
    convertMapUrl(null);
  };

  const goToStation = (station) => {
    // check station data
    if (!station) return;

    // set new target
    const newTarget = {
      ...station,
      type: LOCATION_STATION
    };
    setTarget(newTarget);

    // set UI layout
    setMapMediaStyle(getHomePropertyMediaStyle(newTarget));
    if (isMaximized || isMinimized) handleDefault();
    
    // set focus target on map
    setFocus({
      target: { ...station }
    });

    // update page URL
    convertMapUrl(newTarget);
  };

  const goToSchool = (school) => {
    // check school data
    if (!school) return;

    // set new target
    const newTarget = {
      ...school,
      type: LOCATION_SCHOOL
    };
    setTarget(newTarget);

    // set UI layout
    setMapMediaStyle(getHomePropertyMediaStyle(newTarget));
    if (isMaximized || isMinimized) handleDefault();

    // set focus target on map
    setFocus({
      target: { ...school }
    });

    // update page URL
    convertMapUrl(newTarget);
  };

  const goToUpcoming = (upcomingId) => {
    const upcoming = mapData.upcomings.find(p => p.project === upcomingId);
    if (!upcoming) return;

    // set new target
    const newTarget = {
      ...upcoming,
      id: getCompatNameAsId(`${capitalizeAll(upcoming.project)}${capitalizeAll(upcoming.street)}`),
      type: LOCATION_UPCOMING
    };
    setTarget(newTarget);

    // set UI layout
    setMapMediaStyle(getHomePropertyMediaStyle(newTarget));
    if (isMaximized || isMinimized) handleDefault();

    // set focus target on map
    setFocus({
      target: { ...upcoming }
    });
    
    // update page URL
    convertMapUrl(newTarget);
  };

  /* Search bar interaction */
  const onSearch = (location) => {
    if (location.type === LOCATION_PROPERTY) {
      goToProperty(location.marker, location.store);
    } else if (location.type === LOCATION_SCHOOL) {
      const school = mapData.schools.find(s => s.id === location.store);
      if (school) goToSchool(school);
    } else if (location.type === LOCATION_STATION) {
      const station = mapData.stations.find(s => s.id === location.store);
      if (station) goToStation(station);
    }
  };

  const setFocusOn = (projectName) => {
    const prop = mapData.find(d => d.name === projectName);
    if (prop) {
      setFocus({ target: prop });
    }
  };

  const getProjectLabelFromCompKey = (compKey) => {
    if (!compKey) return compKey;
    const c = compKey.split('_');
    if (c.length !== 2) return compKey;
    const idx = mapData.projectIndex[getProjectKey({ marker: c[0], projectId: c[1] })];
    const project = mapData.projects[idx];
    return project ? getProjectLabel(c[0], project.project) : compKey;
  };

  const addToCompareList = (projKey) => {
    if (projKey in compareList) return;
    const c = projKey.split('_');
    const markerName = c[0];
    const projectId = c[1];
    getJsonFile(`v/${getPropertyFileName(markerName)}`, projectId, json => {
      const data = decompressSingleCompData(json);
      setCompareList({
        ...compareList,
        [projKey]: data
      });
    }, err => {
      // do nothing - soft fail
    });
  };

  const viewComparePro = () => {
    // set new target
    const newTarget = { type: LOCATION_COMPARE };
    setTarget(newTarget);

    // set UI layout
    setMapMediaStyle(getHomePropertyMediaStyle(newTarget));
    if (isMaximized || isMinimized) handleDefault();
  };

  return (
    <>
      <MetaComponent meta={metadata} />
      <div className="header-margin"></div>
      <DefaultHeader user={user} />

      {/* loading screen */}
      {loading && <div className="loader-container"><Loader /></div>}

      {/* error screen */}
      {err && <ErrorContent />}

      {!loading && !err
        && <>
          {/* search bar */}
          <div className="py-10 bg-dark-2">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <FilterBox
                    onSearch={onSearch}
                    hasSearchResults={setHasSearchResults}
                  />
                </div>
              </div>
            </div>
          </div>

          <section className={noSelectClass(user)}>
            <div className="proj-bg-map">
              <MainMap
                user={user}
                target={target}
                focus={focus}
                setFocus={setFocus}
                mapMediaStyle={mapMediaStyle}
                mapData={mapData}
                goToProperty={goToProperty}
                goToMarker={goToMarker}
                goToArea={goToArea}
                goToStation={goToStation}
                goToSchool={goToSchool}
                goToUpcoming={goToUpcoming}
                isMinimized={isMinimized}
                isMaximized={isMaximized}
                setLoading={setLoading}
                setErr={setErr}
                closePropertyDetails={closePropertyDetails}
                loadAdvMapData={loadAdvMapData}
                hasAdvMapData={hasAdvMapData}
                filterSelected={filterSelected}
                setFilterSelected={setFilterSelected}
                compareList={compareList}
                viewComparePro={viewComparePro}
                hasSearchResults={hasSearchResults}
              />

              {target?.type === LOCATION_PROPERTY
                && <PropertyView
                  user={user}
                  session={session}
                  target={target}
                  focus={focus}
                  setFocus={setFocus}
                  setFocusOn={setFocusOn}
                  isMinimized={isMinimized}
                  isMaximized={isMaximized}
                  handleExpand={handleDefault}
                  handleMaximize={handleMaximize}
                  closePropertyDetails={closePropertyDetails}
                  goToProperty={goToProperty}
                  screenDim={screenDim}
                  compareList={compareList}
                  setCompareList={setCompareList}
                  addToCompareList={addToCompareList}
                  mapData={mapData.projects[mapData.projectIndex[target.id]]}
                  viewComparePro={viewComparePro}
                  getProjectLabel={getProjectLabelFromCompKey}
                />
              }

              {(target?.type === LOCATION_AREA || target?.type === LOCATION_MARKER)
                && <AreaView
                  user={user}
                  session={session}
                  target={target}
                  isMinimized={isMinimized}
                  isMaximized={isMaximized}
                  handleExpand={handleDefault}
                  handleMaximize={handleMaximize}
                  closePropertyDetails={closePropertyDetails}
                  goToProperty={goToProperty}
                />
              }

              {(target?.type === LOCATION_STATION || target?.type === LOCATION_SCHOOL)
                && <AmenityView
                  user={user}
                  session={session}
                  target={target}
                  isMinimized={isMinimized}
                  isMaximized={isMaximized}
                  handleExpand={handleDefault}
                  handleMaximize={handleMaximize}
                  closePropertyDetails={closePropertyDetails}
                  goToProperty={goToProperty}
                  goToBlock={goToBlock}
                />
              }

              {target?.type === LOCATION_UPCOMING
                && <UpcomingView
                  user={user}
                  session={session}
                  target={target}
                  isMinimized={isMinimized}
                  isMaximized={isMaximized}
                  handleExpand={handleDefault}
                  handleMaximize={handleMaximize}
                  closePropertyDetails={closePropertyDetails}
                />
              }

              {target?.type === LOCATION_COMPARE
                && <CompareProView
                  user={user}
                  screenDim={screenDim}
                  isMaximized={isMaximized}
                  isMinimized={isMinimized}
                  handleExpand={handleDefault}
                  handleMaximize={handleMaximize}
                  goToProperty={goToProperty}
                  setFocusOn={setFocusOn}
                  compareList={compareList}
                  setCompareList={setCompareList}
                  closeDetails={closePropertyDetails}
                  getProjectLabel={getProjectLabelFromCompKey}
                />
              }
            </div>
          </section>
        </>
      }
    </>
  );
};

export default HomeMap;
