import { NA } from "@/utils/convert";
import {
  getPropertyIconByType,
} from "@/utils/map";
import { checkMediaQuery } from "@/utils/user";
import { Marker } from "react-map-gl/maplibre";

const UpcomingMarker = ({
  idx,
  property,
  goToProperty,
  onMouseEnter,
  onMouseLeave
}) => {

  return (
    <Marker
      key={`upm-${idx}`}
      latitude={property.lat}
      longitude={property.lng}
      anchor="bottom"
      onClick={e => {
        e.originalEvent.stopPropagation();
        goToProperty(property.project);
      }}
    >
      <div
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        data-bs-toggle="offcanvas"
        data-bs-target="#propSidebar"
        data-tooltip-id="upcoming-tooltip"
        data-tooltip-html={checkMediaQuery() ? `<div class="map-tooltip-content"><span class="fw-500">${property.project}</span></div>` : `
          <div class="map-tooltip-content">
          <div class="text-10 text-light-1 fw-600">NEW / UPCOMING PROJECT</div>
          <span class="fw-500">${property.project}</span>
          <div class="text-10 fw-600">${property.street}</div>
          <div class="map-tooltip-l-content">
          Total Units: ${property.totalUnits !== null ? property.totalUnits.toLocaleString() : NA}
          <br />
          Property Types: ${property.types.join(', ')}
          </div>
          <!-- span class="map-tooltip-hint">Click to view details</span -->
          </div>
        `}
        data-tooltip-variant="dark"
        data-tooltip-place="bottom"
      >
        <svg
          height={60}
          viewBox="0 0 24 24"
          style={{
            cursor: 'pointer',
            stroke: 'none',
          }}
        >
          <circle cx="12" cy="10" r="10" fill="#1abc9c" />
          <circle cx="12" cy="10" r="8" fill="#fff" />
          <image
            href={getPropertyIconByType(property.types)}
            x="7" 
            y="5" 
            width="10"
            height="10"
          />
        </svg>
      </div>
    </Marker>
  );
};

export default UpcomingMarker;
