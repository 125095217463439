import { useEffect, useRef, useState } from "react";
import { Dropdown } from "bootstrap";
import { getProjectLabel } from "@/utils/map";

const SearchBar = ({
  searchValue,
  results,
  onInput,
  onSelect,
  onKeyDown,
  shortHeight
}) => {
  const dropdownToggleRef = useRef(null);
  const dropdownInstanceRef = useRef(null);

  const [rowHighlight, setRowHighlight] = useState(null);

  useEffect(() => {
    if (dropdownToggleRef.current) {
      dropdownInstanceRef.current = new Dropdown(dropdownToggleRef.current);
    }
  }, []);

  useEffect(() => {
    if (searchValue && dropdownInstanceRef.current) {
      // Show dropdown when input is typed
      dropdownInstanceRef.current.show();
    }
  }, [searchValue]);

  const onSearchResults = () => {
    onKeyDown();
  };

  const onInputKeyDown = (e) => {
    if (e.key === 'Enter') {
      if (rowHighlight !== null) onSelect(results[rowHighlight]);
      else onSearchResults();
    } else if ((e.key === 'ArrowUp' || e.key === 'Up') && results.length > 0) {
      const lastIdx = results.length - 1;
      const newIdx = rowHighlight === null ? lastIdx : (rowHighlight > 0 ? (rowHighlight - 1) : lastIdx);
      setRowHighlight(newIdx);
      scrollToRow(newIdx);
    } else if ((e.key === 'ArrowDown' || e.key === 'Down') && results.length > 0) {
      const newIdx = rowHighlight === null ? 0 : (rowHighlight + 1) % results.length;
      setRowHighlight(newIdx);
      scrollToRow(newIdx);
    }
  };

  const scrollToRow = (idx) => {
    const row = document.getElementById(`project-search-result-${idx}`);
    if (row) {
      row.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'nearest'
      });
    }
  };

  const onInputChange = (e) => {
    onInput(e.target.value);
    setRowHighlight(null);
  };

  return (
    <>
      <div
        className="searchMenu-loc px-10 py-0 bg-white rounded-4 js-form-dd js-liverSearch"
        onScroll={e => e.stopPropagation()}
        onWheel={e => e.stopPropagation()}
        onTouchMove={e => e.stopPropagation()}
      >
        <div
          ref={dropdownToggleRef}
          data-bs-toggle="dropdown"
          data-bs-auto-close="true"
          data-bs-offset="0,22"
        >
          <div className="text-15 text-light-1 ls-2 lh-16">
            <input
              autoComplete="off"
              type="search"
              placeholder={'Enter project or street name'}
              className="js-search js-dd-focus"
              value={searchValue}
              onChange={(e) => onInputChange(e)}
              onKeyDown={onInputKeyDown}
            />
          </div>
        </div>

        <div className="shadow-2 dropdown-menu propsearch-dropdown">
          {searchValue
            && <>
              {searchValue.length > 2
                &&  <div className={`bg-white px-10 py-10 sm:px-0 sm:py-15 rounded-4 project-search-dropdown ${
                  shortHeight ? 'project-search-dropdown-short' : ''
                }`}>
                  {!results?.length
                    && <p className="text-15 px-20 py-10">No results found</p>
                  }
                  <ul className="y-gap-5 js-results pt-10">
                    {results.map((item, idx) => (
                      <li
                        id={`project-search-result-${idx}`}
                        className={`-link d-block col-12 text-left rounded-4 px-10 py-10 js-search-option mb-1 ${rowHighlight === idx ? 'bg-blue-2' : ''}`}
                        key={idx}
                        role="button"
                        onClick={() => onSelect(item)}
                      >
                        <div className="d-flex text-14">
                          <div className="icon-location-2 text-light-1 pt-4" />
                          <div className="ml-10">
                            <div className="lh-12 fw-500 js-search-option-target">
                              {getProjectLabel(item.marker, item.names[0])}
                            </div>
                            <div className="text-12 lh-12 text-light-1 mt-5">
                              {item.subtype.join(', ')}
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              }
            </>
          }
        </div>
      </div>
    </>
  );
};

export default SearchBar;
