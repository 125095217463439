import { ReactSVG } from 'react-svg';
import Checkbox from "@/components/common/sidebar/Checkbox";
import { MAP_THEMES } from "@/utils/areas";
import { OVERLAY_GROUPS, getProfitableTextClass } from "@/utils/map";
import { checkMediaQuery } from "@/utils/user";

const LEGEND = [
  { label: 'Landed Property', img: '/img/general/house.png', hint: 'Detached House, Semi-Detached, Terrace House' },
  { label: 'Apartment/Condo', img: '/img/general/flat.png', hint: 'Apartment, Condominium' },
  { label: 'Executive Condo', img: '/img/general/condo.png', hint: 'Executive Condominium' },
  { label: 'Mixed Property', img: '/img/general/mixed.png', hint: 'Mixed landed and non-landed property types' },
  { label: 'Cluster', img: '/img/general/p_high.png', hint: 'Cluster of properties which has more than 90% profitability on average', sublabel: '>90%', value: 100 },
  { label: 'Cluster', img: '/img/general/p_mid.png', hint: 'Cluster of properties which has more than 70% profitability on average', sublabel: '>70%', value: 80 },
  { label: 'Cluster', img: '/img/general/p_low.png', hint: 'Cluster of properties which has more than 50% profitability on average', sublabel: '>50%', value: 60 },
  { label: 'Cluster', img: '/img/general/p_lowest.png', hint: 'Cluster of properties which has less than 50% profitability on average', sublabel: '<50%', value: 0 },
  { label: 'Cluster', img: '/img/general/p_none.png', hint: 'Cluster of properties which has no profitability data', sublabel: 'No Data', value: -1 },
  { label: 'School', img: '/img/general/school.png', hint: 'Preschool, Primary, Secondary, Tertiary School' },
  { label: 'MRT', img: '/img/general/ic_mrt.png', hint: 'MRT Station, Station Exits' },
  { label: 'LRT', img: '/img/general/ic_lrt.png', hint: 'LRT Station' },
];

const MapButtons = ({
  theme,
  setTheme,
  onSelectFilter,
  // markerSchemeOptions,
  // markerScheme,
  // setMarkerScheme,
  onSelectCompare,
  overlayOpacity,
  setOverlayOpacity,
  hiddenOverlayIds,
  setHiddenOverlayIds,
  numFilters
}) => {
  const isSmallScreen = window.innerWidth < 1000;

  return (
    <>
      <div className="d-flex d-row justify-content-between">
        <div className="p-2 dropdown js-dropdown js-category-active heatmap-selector-menu map-mode-selector proj-map-spad">
          <div
            className="dropdown__button dropdown__button_sm d-flex items-center align-items-center bg-white border-light rounded-100 px-5 py-5 text-12 lh-12"
            data-bs-toggle="dropdown"
            data-bs-auto-close="true"
            aria-expanded="false"
            data-bs-offset="0,10"
            data-tooltip-id="map-tooltip"
            data-tooltip-html={`
              <div class="map-tooltip-content">
              <span class="fw-500">${theme} map</span>
              <br />
              <span class="map-tooltip-hint">Click to change map style</span>
              </div>
            `}
            data-tooltip-variant="dark"
            data-tooltip-place="bottom"
          >
            <span className="js-dropdown-map">
              <img id="map-mode-ic" className="map-mode-icon" height={25} width={25} src={MAP_THEMES.find(t => t.label === theme).icon} />
            </span>
            {!checkMediaQuery() && <i className="icon icon-chevron-sm-down text-7 ml-10" />}
          </div>
          <div className="toggle-element -dropdown dropdown-menu dropdown-top">
            <div className="text-12 y-gap-20 js-dropdown-list">
              {MAP_THEMES.map((option, index) => (
                <div key={index}>
                  <button
                    className={`d-block js-dropdown-link ${
                      theme === option.label ? "text-blue-1 " : ""
                    }`}
                    onClick={() => {
                      setTheme(option.label);
                      document.getElementById('map-mode-ic').src = option.icon;
                    }}
                  >
                    <img className="map-mode-icon mr-20" height={25} width={25} src={option.icon} />
                    {option.label}
                  </button>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="p-2 dropdown js-dropdown js-category-active heatmap-selector-menu map-mode-selector proj-map-spad">
          <div
            className="dropdown__button dropdown__button_sm d-flex items-center align-items-center bg-white border-light rounded-100 px-10 py-5 text-12 lh-12 "
            data-bs-toggle="dropdown"
            data-bs-auto-close="true"
            aria-expanded="false"
            data-bs-offset="0,10"
            data-tooltip-id="map-tooltip"
            data-tooltip-html={`
              <div class="map-tooltip-content">
              <span class="fw-500">Overlay Setting</span>
              <br />
              <span class="map-tooltip-hint">Click to configure map overlays</span>
              </div>
            `}
            data-tooltip-variant="dark"
            data-tooltip-place="bottom"
          >
            <span className="js-dropdown-map map-btn-icon">
              <ReactSVG
                src="/img/general/overlay.svg"
                beforeInjection={(svg) => {
                  svg.querySelectorAll('[fill]').forEach((element) => element.removeAttribute('fill'));
                }}
                // height={24}
                // width={24}
                // src="/img/general/layers.png"
              />
            </span>
            {!checkMediaQuery() && <i className="icon icon-chevron-sm-down text-7 ml-5" />}
          </div>
          <div className="toggle-element -dropdown dropdown-menu overlay-menu">
            <div className="text-12 y-gap-5 js-dropdown-list">
              <div className="fw-600 text-blue-1">OPACITY {(overlayOpacity * 100).toFixed(0)}%</div>
              <input type="range" min={0} max={1} value={overlayOpacity} step={0.05} style={{ width: '100%' }} onChange={e => setOverlayOpacity(parseFloat(e.target.value))} />
              <hr />
              <div className="fw-600 text-blue-1">SHOW / HIDE</div>
              {OVERLAY_GROUPS.map((option, index) => (
                <div key={index}>
                  <Checkbox
                    label={option.label}
                    value={!option.ids.some(id => hiddenOverlayIds.indexOf(id) >= 0)}
                    setValue={v => {
                      if (v) setHiddenOverlayIds(hiddenOverlayIds.filter(id => option.ids.indexOf(id) < 0));
                      else setHiddenOverlayIds([...new Set([...hiddenOverlayIds, ...option.ids])])
                    }}
                    textSize="14"
                  />
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="p-2 dropdown js-dropdown js-category-active heatmap-selector-menu map-mode-selector proj-map-spad">
          <div
            className="dropdown__button dropdown__button_sm d-flex items-center align-items-center bg-white border-light rounded-100 px-10 py-5"
            // data-bs-toggle="dropdown"
            // data-bs-auto-close="true"
            aria-expanded="false"
            data-bs-offset="0,10"
            data-tooltip-id="map-tooltip"
            data-tooltip-html={`
              <div class="map-tooltip-content">
              <span class="fw-500">Color by Profitable %</span>
              <div class="map-tooltip-l-content text-12">
              <span class="text-14">Color range for % transactions
              <br />
              which are profitable:</span>
              <br />
              <span class="map-marker-color-red">RED: > 90%</span>
              <br />
              <span class="map-marker-color-orange">ORANGE: > 70%</span>
              <br />
              <span class="map-marker-color-yellow">YELLOW: > 50%</span>
              <br />
              <span class="map-marker-color-blue">BLUE: < 50%</span>
              <br />
              <span>GREY: No Data</span>
              </div>
            `}
            data-tooltip-variant="dark"
            data-tooltip-place="bottom"
          >
            <div className="map-color-legend map-color-grad">
              <span className="low">HIGH</span>
              <span className="high">LOW</span>
            </div>
            <div className="map-color-legend map-color-na ml-5">
              <span className="">NA</span>
            </div>
            {/* <i className="icon icon-chevron-sm-down text-7 ml-5" /> */}
          </div>
          {/* <div className="toggle-element -dropdown dropdown-menu dropdown-top">
            <div className="text-12 y-gap-20 js-dropdown-list">
              {markerSchemeOptions.map((option, index) => (
                <div key={index}>
                  <button
                    className={`d-block js-dropdown-link ${
                      markerScheme === option.id ? "text-blue-1 " : ""
                    }`}
                    onClick={() => {
                      setMarkerScheme(option.id);
                    }}
                    data-tooltip-id="map-tooltip"
                    data-tooltip-html={`
                      <div class="map-tooltip-content">
                      Show map marker color range for ${option.label}
                      </div>
                    `}
                    data-tooltip-variant="dark"
                    data-tooltip-place="bottom"
                  >
                    {option.label}
                  </button>
                </div>
              ))}
            </div>
          </div> */}
        </div>

        <div className="p-2 flex-grow-1"></div>

        <div className="p-2 dropdown js-dropdown js-category-active heatmap-selector-menu map-mode-selector proj-map-spad">
          <div
            className="dropdown__button dropdown__button_sm d-flex items-center align-items-center bg-white border-light rounded-100 px-10 py-5 text-12 lh-12 text-blue-1"
            data-bs-toggle="offcanvas"
            data-bs-target="#mapSidebar"
            onClick={() => onSelectFilter({ type: 'filter' })}
            data-tooltip-id="map-tooltip"
            data-tooltip-html={`
              <div class="map-tooltip-content">
              <span class="fw-500">Filter Properties</span>
              ${numFilters > 0
                ? `<br /><span class="map-tooltip-hint">${numFilters} filter${numFilters > 1 ? 's' : ''} selected</span>`
                : ''
              }
              </div>
            `}
            data-tooltip-variant="dark"
            data-tooltip-place="bottom"
          >
            <span className="js-dropdown-title text-12">
              <i className="icon icon-menu" />
              {!isSmallScreen
                && <>
                  <span className="ml-5 fw-600">Filter</span>
                  {numFilters > 0
                    && <span className="fw-600"> {`(${numFilters})`}</span>
                  }
                </>
              }
            </span>
          </div>
        </div>

        <div className="p-2 dropdown js-dropdown js-category-active heatmap-selector-menu map-mode-selector proj-map-spad">
          <div
            className="dropdown__button dropdown__button_sm d-flex items-center align-items-center bg-white border-light rounded-100 px-10 py-5 text-12 lh-12 text-blue-1"
            onClick={() => onSelectCompare()}
            data-tooltip-id="map-tooltip"
            data-tooltip-html={`
              <div class="map-tooltip-content">
              <span class="fw-500">Compare PRO</span>
              </div>
            `}
            data-tooltip-variant="dark"
            data-tooltip-place="bottom"
          >
            <span className="js-dropdown-title">
              <i className="icon icon-award text-12" />
              {!isSmallScreen
                && <span className="ml-5 text-12 fw-600">Compare</span>
              }
            </span>
          </div>
        </div>

        <div className="p-2 dropdown js-dropdown js-category-active heatmap-selector-menu map-mode-selector proj-map-spad">
          <div
            className="dropdown__button dropdown__button_sm d-flex items-center align-items-center bg-white border-light rounded-100 px-5 py-5 text-12 lh-12 "
            data-bs-toggle="dropdown"
            data-bs-auto-close="true"
            aria-expanded="false"
            data-bs-offset="0,10"
            data-tooltip-id="map-tooltip"
            data-tooltip-html={`
              <div class="map-tooltip-content">
              <span class="fw-500">Map Legend</span>
              </div>
            `}
            data-tooltip-variant="dark"
            data-tooltip-place="bottom"
          >
            <span className="js-dropdown-map map-btn-icon">
              <ReactSVG
                src="/img/general/help.svg"
                beforeInjection={(svg) => {
                  svg.querySelectorAll('[fill]').forEach((element) => element.removeAttribute('fill'));
                }}
              />
            </span>
          </div>
          <div className="toggle-element -dropdown dropdown-menu legend-menu">
            <div className="text-12 y-gap-5 js-dropdown-list">
              <div className="fw-600 text-blue-1">Map Icons</div>
              {LEGEND.map((item, i) =>
                <div
                  key={i}
                  className="legend-row"
                  data-tooltip-id="map-tooltip"
                  data-tooltip-html={`
                    <div class="map-tooltip-content">
                    <span class="fw-500">${item.hint}</span>
                    </div>
                  `}
                  data-tooltip-variant="dark"
                  data-tooltip-place="bottom"
                >
                  <img src={item.img} alt={item.label} className="legend-img" />
                  <span>{item.label}{item.sublabel ? <span className={getProfitableTextClass(item.value)}>{` (${item.sublabel})`}</span> : ''}</span>
                </div>
              )}
            </div>
          </div>
        </div>
      
      </div>
    </>
  );
};

export default MapButtons;
