import {
  NA,
  calculateAge,
  calculateLeaseRemainV2
} from "@/utils/convert";

const Factsheet = ({ data, isMaximized, screenDim }) => {
  const getTrendClass = (trend) => {
    if (trend === 0) return '';
    return trend > 0 ? 'text-green-2' : 'text-red-1';
  };

  const getProfitableClass = () => {
    if (data.profitable === null) return getTrendClass(0);
    if (data.profitable > 0) return getTrendClass(1);
    return getTrendClass(-1);
  };

  const generateValue = (value) => {
    if (value === -1) return NA;
    return value ?? NA;
  };

  const isXXL = isMaximized ? screenDim.width >= 1000 : screenDim.width >= 1700;
  const isSuperXXL = isMaximized && screenDim.width >= 1300;
  const xxlGrid = isSuperXXL ? (screenDim.width >= 1700 ? 2 : 3) : (isXXL ? 4 : 6);

  const simplifyPropTypes = (types) => types.map(t => t.replace('House', '').trim());

  return (
    <>
      <div className={`col-xl-${xxlGrid} col-lg-6 col-md-12`}>
        <div className="mt-10 px-10">
          <div className="d-flex items-center text-15">
            <i className="icon-city-2 mr-10"></i>
            <div className="fw-500">General</div>
          </div>

          <div className="row x-gap-50 y-gap-0 text-12 pt-5">
            <div className="col-12">
              <div className="">
                <span className="proj-lw">Developer:</span>
                {data.developer ?? NA}
              </div>
            </div>

            <div className="col-12">
              <div className="">
                <span className="proj-lw">Property Type:</span>
                {simplifyPropTypes(data.types).join(', ') ?? NA}
              </div>
            </div>

            <div className="col-12">
              <div className="">
                <span className="proj-lw">Completion:</span>
                {data.completion ?? NA}
              </div>
            </div>

            <div className="col-12">
              <div className="">
                <span className="proj-lw">Total Units:</span>
                {(data.totalUnits === null || data.totalUnits < 0) ? NA : data.totalUnits}
              </div>
            </div>

            <div className="col-12">
              <div className="">
                <span className="proj-lw">Unit Size:</span>{
                  data.minSize && data.maxSize
                    ? (data.minSize !== data.maxSize
                      ? `${data.minSize.toLocaleString()} - ${data.maxSize.toLocaleString()} sft`
                      : `${data.minSize.toLocaleString()} sft`
                    )
                    : NA
                }
              </div>
            </div>

            <div className="col-12">
              <div className="d-flex px-0 py-0">
                <div className="proj-lw p-2 px-0 py-0">Tenure:</div>
                <div className="p-2 px-0 py-0">{data.tenures?.map((t, i) => (
                  <>
                    {i > 0 && <br />}
                    {t}
                  </>
                )) ?? NA}</div>
              </div>
            </div>

            <div className="col-12">
              <div className="">
                <span className="proj-lw">Age:</span>
                {calculateAge(data.tenureDate, data.completion)}
              </div>
            </div>

            <div className="col-12">
              <div className="">
                <span className="proj-lw">Lease Remaining:</span>
                {calculateLeaseRemainV2(data.tenureYears, data.tenureDate)}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={`col-xl-${xxlGrid} col-lg-6 col-md-12`}>
        <div className="mt-10 px-10">
          <div className="d-flex items-center text-15">
            <i className="icon-price-label mr-10"></i>
            <div className="fw-500">Profitability</div>
          </div>

          <div className="row x-gap-50 y-gap-0 text-12 pt-5">
            <div className="col-12">
              <div className="">
                <span className="proj-lw">Profitable %:</span>
                <span className={getProfitableClass()}>
                  {data.profitable === null || data.profitable < 0 ? NA : `${(data.profitable).toFixed(0)}%`}
                </span>
              </div>
            </div>

            <div className="col-12">
              <div className="">
                <span className="proj-lw">No. Profitable:</span>
                <span className="text-green-2">
                  {data.profitTx === null ? NA : data.profitTx.toLocaleString()}
                </span>
              </div>
            </div>

            <div className="col-12">
              <div className="">
                <span className="proj-lw">&gt; 6% Annualized:</span>
                <span className="text-green-2">
                  {data.perc6ProfitTx === null ? NA : data.perc6ProfitTx.toLocaleString()}
                </span>
              </div>
            </div>

            <div className="col-12">
              <div className="">
                <span className="proj-lw">No. Unprofitable:</span>
                <span className="text-red-1">
                  {data.unprofitTx === null ? NA : data.unprofitTx.toLocaleString()}
                </span>
              </div>
            </div>

            <div className="col-12">
              <div className="">
                <span className="proj-lw">Total Transactions:</span>
                {data.totalTx === null ? NA : data.totalTx.toLocaleString()}
              </div>
            </div>
            
            <div className="col-12">
              <div className="">
                <span className="proj-lw">Total Profits:</span>
                <span className={getTrendClass(data.totalProfits)}>
                  {data.totalProfits < 0 ? '-' : ''}${
                    (Math.abs(data.totalProfits ?? 0)).toLocaleString()
                  }
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={`col-xl-${xxlGrid} col-lg-6 col-md-12`}>
        <div className="mt-10 px-10">
          <div className="d-flex items-center text-15">
            <i className="icon-usd mr-10"></i>
            <div className="fw-500">Transactions</div>
          </div>

          <div className="row x-gap-50 y-gap-0 text-12 pt-5">
            <div className="col-12">
              <div className="">
                <span className="proj-lw">Sold At Launch:</span>
                <span className={data.soldAtLaunch ? 'text-green-2' : 'text-dark-1'}>
                  {data.soldAtLaunch === null ? NA : data.soldAtLaunch.toFixed(0)}%
                </span>
              </div>
            </div>

            <div className="col-12">
              <div className="">
                <span className="proj-lw">HDB Buyers:</span>
                <span className="text-green-2">
                  {data.hdbBuyers === null ? NA : data.hdbBuyers.toFixed(0)}%
                </span>
              </div>
            </div>

            <div className="col-12">
              <div className="">
                <span className="proj-lw">Developer Unsold:</span>
                {data.unsoldUnits ?? 0}
              </div>
            </div>

            <div className="col-12">
              <div className="">
                <span className="proj-lw">Total Transacted:</span>
                {data.totalTxAmt ? `$${data.totalTxAmt}` : NA}
              </div>
            </div>

            <div className="col-12">
              <div className="">
                <span className="proj-lw">Last Transacted:</span>
                {data.lastTxDate ?? NA}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={`col-xl-${xxlGrid} col-lg-6 col-md-12`}>
        <div className="mt-10 px-10">
          <div className="d-flex items-center text-15">
            <i className="icon-compass mr-10"></i>
            <div className="fw-500">Location</div>
          </div>

          <div className="row x-gap-50 y-gap-0 text-12 pt-5">
            <div className="col-12">
              <div className="">
                <span className="proj-lw">Market Segment:</span>
                {data.marketSegment}
              </div>
            </div>

            <div className="col-12">
              <div className="">
                <span className="proj-lw">Area:</span>
                {data.area}
              </div>
            </div>

            <div className="col-12">
              <div className="">
                <span className="proj-lw">Region:</span>
                {data.region}
              </div>
            </div>

            <div className="col-12">
              <div className="">
                <span className="proj-lw">District:</span>
                {data.district}
              </div>
            </div>

            <div className="col-12">
              <div className="">
                <span className="proj-lw">Postal Sector:</span>
                {data.sector}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={`col-xl-${xxlGrid} col-lg-6 col-md-12`}>
        <div className="mt-10 px-10">
          <div className="d-flex items-center text-15">
            <i className="icon-living-room mr-10"></i>
            <div className="fw-500">Rental</div>
          </div>

          <div className="row x-gap-50 y-gap-0 text-12 pt-5">
            <div className="col-12">
              <div className="">
                <span className="proj-lw fw-600 text-blue-1">Past 6 months</span>
              </div>
            </div>

            <div className="col-12">
              <div className="">
                <span className="proj-lw">Avg PSF Rental:</span>
                {generateValue(data.last6mAvgRentPsf) === NA ? NA : `$${data.last6mAvgRentPsf.toFixed(2)}`}
              </div>
            </div>

            <div className="col-12">
              <div className="">
                <span className="proj-lw">No. of Rentals:</span>
                {generateValue(data.last6mTotalRent) === NA ? NA : data.last6mTotalRent.toFixed(0)}
              </div>
            </div>

            <div className="col-12">
              <div className="">
                <span className="proj-lw fw-600 text-blue-1">Past 1 month</span>
              </div>
            </div>

            <div className="col-12">
              <div className="">
                <span className="proj-lw">Avg PSF Rental:</span>
                {generateValue(data.last1mAvgRentPsf) === NA ? NA : `$${data.last1mAvgRentPsf.toFixed(2)}`}
              </div>
            </div>

            <div className="col-12">
              <div className="">
                <span className="proj-lw">No. of Rentals:</span>
                {generateValue(data.last1mTotalRent) === NA ? NA : data.last1mTotalRent.toFixed(0)}
              </div>
            </div>

          </div>
        </div>
      </div>

      <div className={`col-xl-${xxlGrid} col-lg-6 col-md-12`}></div>
    </>
  );
};

export default Factsheet;
