import PropTypes from "prop-types";
import CustomTable from './CustomTable';
import { getRegionOfArea } from '@/utils/areas';
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";

const tableConfig = {
  placeholder: 'Search project...',
  manualPageSize: [10, 20, 30, 40, 50, 100],
};

const ProfitTable = ({
  results,
  pageLimit, setPageLimit,
  customSort, onCustomSort,
  defaultColumns, fullColumns, setFullColumns, columns, setColumns,
  groupOptions, selectedGroupOption, setSelectedGroupOption, originalColumns,
  showHistory,
}) => {
  const defaultStyle = { height: `calc(100svh - ${showHistory ? 358 : 288}px)`, minHeight: '360px' };
  const [style, setStyle] = useState(defaultStyle);

  const genTransactionSearchLink = (area) => {
    let query = `area=${area}`;
    const region = getRegionOfArea(area);
    if (region) query += `&region=${region}`;
    return query;
  };

  const format = (columns) => columns.map(col => {
    if (col.accessor === 'area') {
      // eslint-disable-next-line react/display-name
      col.Cell = (row) => (
        <Link
          className='button ml-auto p-1 text-14 -blue-1-05 bg-white text-blue-1'
          to={`/transactions?${genTransactionSearchLink(row.cell.value)}`}
        >
          {row.cell.value}
        </Link>
      );
    }
    return col;
  });
  
  const updatedDefaultColumns = format(defaultColumns);
  const updatedColumns = format(columns);
  const updatedFullColumns = format(fullColumns);

  useEffect(() => {
    const updateStyle = () => {
      const isLandscape = window.innerWidth > window.innerHeight;
      if (isLandscape && window.innerHeight < 600) {
        setStyle({ height: '100%' });
      } else {
        setStyle(defaultStyle);
      }
    };

    updateStyle();

    window.addEventListener('resize', updateStyle);

    return () => window.removeEventListener('resize', updateStyle);
  }, [showHistory]);

  return (
    <CustomTable
      name="profits"
      data={results}
      tableConfig={tableConfig}
      pageLimit={pageLimit}
      setPageLimit={setPageLimit}
      customSort={customSort}
      onCustomSort={onCustomSort}
      defaultColumns={updatedDefaultColumns}
      fullColumns={updatedFullColumns}
      setFullColumns={setFullColumns}
      columns={updatedColumns}
      setColumns={setColumns}
      style={style}
      groupOptions={groupOptions}
      selectedGroupOption={selectedGroupOption}
      setSelectedGroupOption={setSelectedGroupOption}
      originalColumns={originalColumns}
      hidePage
      showFilterButton
    />
  );
};

ProfitTable.propTypes = {
  results: PropTypes.array,
  filterAreas: PropTypes.object,
  setFilterAreas: PropTypes.func.isRequired,
  onResetFilter: PropTypes.func.isRequired,
  loadTransactions: PropTypes.func.isRequired,
  scrollToTx: PropTypes.func.isRequired,
  pageLimit: PropTypes.number,
  setPageLimit: PropTypes.func,
  customSort: PropTypes.object,
  onCustomSort: PropTypes.func,
  defaultColumns: PropTypes.array,
  fullColumns: PropTypes.array,
  setFullColumns: PropTypes.func,
  columns: PropTypes.array,
  setColumns: PropTypes.func,
  groupOptions: PropTypes.array,
  selectedGroupOption: PropTypes.object,
  setSelectedGroupOption: PropTypes.func,
  showHistory: PropTypes.bool,
};

ProfitTable.defaultProps = {
  groupOptions: null,
  selectedGroupOption: null,
  setSelectedGroupOption: null,
  showHistory: false,
};

export default ProfitTable;
