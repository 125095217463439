import { checkMediaQuery, noSelectClass } from "@/utils/user";
import ScrollToTop from "../project/ScrollToTop";
import AreaTable from "./table/AreaTable";

const AreaView = ({
  user,
  err,
  target,
  isMinimized,
  isMaximized,
  handleMaximize,
  handleExpand,
  closePropertyDetails,
  goToProperty
}) => {
  const mediaMatches = checkMediaQuery();

  const properties = (target.isCluster || target.isMarker) ? target.projects : target.projects.flatMap(p => p.properties);

  return (
    <>
      {!isMinimized && err &&
        <div className={`modal-container ${
          isMaximized ? "modal-full modal-container-fixed" : (
            mediaMatches ? "" : "modal-container-fixed"
          )
        }`}>

          {mediaMatches
            && <div className="d-flex justify-content-between text-10 text-light-1">
              <div className="p-2 py-0"></div>
              <div
                className={`p-2 py-0 mb-10 cursor-pointer ${
                  isMaximized ? '' : 'rotate-180'
                }`}
                onClick={() => isMaximized ? handleExpand() : handleMaximize()}
              >
                <i className="icon-chevron-sm-down py-5" />
              </div>
              <div
                className="p-2 pt-5 pb-0 cursor-pointer"
                onClick={() => closePropertyDetails()}
              >
                <i className="icon-close py-5" />
              </div>
            </div>
          }

          {!mediaMatches
            && <div className="d-flex justify-content-end text-10 text-light-1">
              <div
                className="p-2 px-10 pt-5 pb-0 cursor-pointer"
                onClick={() => closePropertyDetails()}
              >
                <i className="icon-close" />
              </div>
            </div>
          }

          <div className="modal-body watermark d-flex">
            {!mediaMatches
              && <div
                className="p-2 modal-expand button text-10 px-0 d-flex align-items-center cursor-pointer"
                onClick={() => isMaximized ? handleExpand() : handleMaximize()}
              >
                <div className={isMaximized ? 'rotate-270' : 'rotate-90'}>
                  <i className="icon-chevron-sm-down px-5" />
                </div>
              </div>
            }

            <div
              id="project-details-modal"
              className={`p-2 modal-scoll-content text-center fw-500 py-40 ${
                isMaximized ? 'modal-scroll-am-full' : (mediaMatches ? 'modal-scroll-am' : 'modal-scoll-content-am')
              }`}
            >
              Planning area not found
            </div>
          </div>
        </div>
      }

      {!isMinimized && !err && (
        <div className={`modal-container ${
          isMaximized ? "modal-full modal-container-fixed" : (
            mediaMatches ? "" : "modal-container-fixed"
          )
        }`}>

          {mediaMatches
            && <div className="d-flex justify-content-between text-10 text-light-1">
              <div className="p-2 py-0"></div>
              <div
                className={`p-2 py-0 mb-10 cursor-pointer ${
                  isMaximized ? '' : 'rotate-180'
                }`}
                onClick={() => isMaximized ? handleExpand() : handleMaximize()}
              >
                <i className="icon-chevron-sm-down py-5" />
              </div>
              <div
                className="p-2 pt-5 pb-0 cursor-pointer"
                onClick={() => closePropertyDetails()}
              >
                <i className="icon-close py-5" />
              </div>
            </div>
          }

          {!mediaMatches
            && <div className="d-flex justify-content-end text-10 text-light-1">
              <div
                className="p-2 px-10 pt-5 pb-0 cursor-pointer"
                onClick={() => closePropertyDetails()}
              >
                <i className="icon-close" />
              </div>
            </div>
          }

          <div className="modal-body watermark d-flex">
            {!mediaMatches
              && <div
                className="p-2 modal-expand button text-10 px-0 d-flex align-items-center cursor-pointer"
                onClick={() => isMaximized ? handleExpand() : handleMaximize()}
              >
                <div className={isMaximized ? 'rotate-270' : 'rotate-90'}>
                  <i className="icon-chevron-sm-down px-5" />
                </div>
              </div>
            }

            <div
              id="project-details-modal"
              className={`p-2 modal-scoll-content ${
                isMaximized ? 'modal-scroll-am-full' : (mediaMatches ? 'modal-scroll-am' : 'modal-scoll-content-am')
              }`}
            >

              <ScrollToTop />
            
              <section className={`modal-table${isMaximized ? '-full' : ''}`}>
                <div className={`data-table ${noSelectClass(user)}`}>
                  <div className="d-flex noselect">
                    <i className="p-2 icon-city text-18" />
                    <h3 className="p-2 text-15 fw-500">{
                      target.isCluster
                        ? `${properties.length} properties`
                        : (
                          target.isMarker
                            ? `${target.name} (${properties.length} properties)`
                            : `District ${target.id} (${properties.length} properties)`
                        )
                    }</h3>
                  </div>
                  <AreaTable
                    data={properties.map(p => ({
                      ...p,
                      profitable: p.profitable === null ? -1 : p.profitable
                    })).sort((a, b) => (b.profitable - a.profitable) || ((b.totalTx ?? 0) - (a.totalTx ?? 0)))}
                    goToProperty={data => {
                      const idx = data.cell.row.index;
                      goToProperty(data.data[idx].marker, data.data[idx].projectId);
                    }}
                  />
                </div>
              </section>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AreaView;
