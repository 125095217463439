import { checkMediaQuery } from "@/utils/user";
import ScrollToTop from "../project/ScrollToTop";
import { useEffect, useState } from "react";
import { getFile } from "@/utils/api";
import Loader from "../common/Loader";
import { decompressUpcomingData } from "@/utils/map";

const UpcomingView = ({
  user,
  target,
  isMinimized,
  isMaximized,
  handleMaximize,
  handleExpand,
  closePropertyDetails
}) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  const mediaMatches = checkMediaQuery();

  useEffect(() => {
    setLoading(true);
    getFile('u', target.id, txt => {
      const data = decompressUpcomingData(txt);
      setData(data);
      setLoading(false);
    }, err => {
      setLoading(false);
    });
  }, [target]);

  return (
    <>
      {loading
        && <div className="loader-container">
          <Loader />
        </div>
      }

      {!isMinimized && !loading && (
        <div className={`modal-container ${
          isMaximized ? "modal-full modal-container-fixed" : (
            mediaMatches ? "" : "modal-container-fixed"
          )
        }`}>

          {mediaMatches
            && <div className="d-flex justify-content-between text-10 text-light-1">
              <div className="p-2 py-0"></div>
              <div
                className={`p-2 py-0 mb-10 cursor-pointer ${
                  isMaximized ? '' : 'rotate-180'
                }`}
                onClick={() => isMaximized ? handleExpand() : handleMaximize()}
              >
                <i className="icon-chevron-sm-down py-5" />
              </div>
              <div
                className="p-2 pt-5 pb-0 cursor-pointer"
                onClick={() => closePropertyDetails()}
              >
                <i className="icon-close py-5" />
              </div>
            </div>
          }

          {!mediaMatches
            && <div className="d-flex justify-content-end text-10 text-light-1">
              <div
                className="p-2 px-10 pt-5 pb-0 cursor-pointer"
                onClick={() => closePropertyDetails()}
              >
                <i className="icon-close" />
              </div>
            </div>
          }

          <div className="modal-body watermark d-flex">
            {!mediaMatches
              && <div
                className="p-2 modal-expand button text-10 px-0 d-flex align-items-center cursor-pointer"
                onClick={() => isMaximized ? handleExpand() : handleMaximize()}
              >
                <div className={isMaximized ? 'rotate-270' : 'rotate-90'}>
                  <i className="icon-chevron-sm-down px-5" />
                </div>
              </div>
            }

            <div
              id="upcoming-details-modal"
              className={`p-2 modal-scoll-content ${
                isMaximized ? 'modal-scroll-am-full' : (mediaMatches ? 'modal-scroll-am' : 'modal-scoll-content-am')
              }`}
            >

              <ScrollToTop />

              <section>

                <div className="mt-10 px-10">
                  <div className="d-flex items-center text-15">
                    <i className="icon-city-2 mr-10"></i>
                    <div className="fw-500">
                      {target.project}
                      <span className="text-blue-1">&nbsp;&nbsp;&nbsp;(New/Upcoming Project)</span>
                    </div>
                  </div>

                  <div className="row x-gap-50 y-gap-0 text-12 pt-5">
                    <div className="col-12">
                      <div className="">
                        <span className="proj-lw">Street:</span>
                        {target.street}
                      </div>
                    </div>

                    {data?.developer
                      && <div className="col-12">
                        <div className="">
                          <span className="proj-lw">Developer:</span>
                          {data.developer}
                        </div>
                      </div>
                    }

                    <div className="col-12">
                      <div className="">
                        <span className="proj-lw">Property Type:</span>
                        {target.types.join(', ')}
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="">
                        <span className="proj-lw">Total Units:</span>
                        {target.totalUnits.toLocaleString()}
                      </div>
                    </div>
                  </div>
                </div>

              </section>
            
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default UpcomingView;
