import { useState } from 'react';
import CustomTable from '@/components/transactions/CustomTable';
import { NA, formatDistanceLabel } from '@/utils/convert';
import { STANDARD_PAGE_CONFIG } from '@/utils/table';

const AmenityTable = ({
  data,
  goToProperty,
  goToBlock
}) => {
  const DEFAULT_COLUMNS = [
    {
      Header: 'Project',
      accessor: 'label',
      disableGlobalFilter: true,
      Cell: (row) =>
        <a
          className="text-blue-1 cursor-pointer"
          onClick={() => goToProperty(row)}
        >
          {row.cell.value}
        </a>
    },
    {
      Header: 'Street / Address',
      accessor: 'address',
      disableGlobalFilter: true,
      Cell: (row) => <a
        className="text-blue-1 cursor-pointer"
        onClick={() => goToBlock(row)}
      >
        {row.cell.value}
      </a>
    },
    {
      Header: 'Distance',
      accessor: 'distance',
      disableGlobalFilter: true,
      Cell: (row) => formatDistanceLabel(row.cell.value)
    },
  ];

  const [fullColumns, setFullColumns] = useState(DEFAULT_COLUMNS.map(col => ({ ...col, enabled: true })));
  const [columns, setColumns] = useState(DEFAULT_COLUMNS);

  return (
    <CustomTable
      data={data}
      tableConfig={STANDARD_PAGE_CONFIG}
      defaultColumns={DEFAULT_COLUMNS}
      fullColumns={fullColumns}
      setFullColumns={setFullColumns}
      columns={columns}
      setColumns={setColumns}
      hideSearchInput
      hidePageButton
      hidePage
      pageLimit={1000}
      disableCustomColumns={true}
    />
  );
};

export default AmenityTable;
