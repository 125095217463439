import { useCallback, useEffect, useRef, useState } from "react";
import Map, {
  Layer,
  Marker,
  Popup,
  Source
} from "react-map-gl/maplibre";
import { LngLatBounds, addProtocol } from "maplibre-gl";
import { PMTiles, Protocol } from "pmtiles";
import Supercluster from "supercluster";
import { debounce } from 'lodash';
import { Tooltip } from "react-tooltip";
import {
  DEFAULT_MAP_THEME,
  LOCATION_AREA,
  LOCATION_BLOCK,
  LOCATION_PROPERTY,
  LOCATION_UPCOMING,
  MARKET_SEGMENT_ID_MAP,
  getMapStyleFromTheme,
  getTenureValue,
  isRegion,
  transformMapboxStyleRequest
} from "@/utils/areas";
import RadiusOverlay from "./map/RadiusOverlay";
import { DEBOUNCE_TIMING } from "@/utils/api";
import {
  checkMediaQuery,
  noSelectClass
} from "@/utils/user";
import AreaMarker from "./map/AreaMarker";
import MapButtons from "./map/MapButtons";
import PropertyMarker from "./map/PropertyMarker";
import {
  MAP_FILTER_OPTIONS,
  OVERLAY_TYPES_MAP,
  generateLinesData,
  getOverlayFill,
  getOverlaysOpacity,
  getProjectKeyByTarget,
  groupMarkerByArea,
  groupPropsByMarker
} from "@/utils/map";
import {
  FIELD_AREAS,
  FIELD_DISTRICTS,
  FIELD_MAX_SIZE,
  FIELD_MAX_UNIT_PRICE,
  FIELD_MIN_SIZE,
  FIELD_MIN_UNIT_PRICE,
  FIELD_REGIONS,
  FILTER_AVG_PRICE,
  FILTER_AVG_UNIT_PRICE,
  FILTER_COMPLETION_DATE,
  FILTER_HDB_BUYERS,
  FILTER_LAUNCH_PERC,
  FILTER_LOCATION,
  FILTER_MAP_TRANSACTION_DATE,
  FILTER_PROFITABLE,
  FILTER_PROPERTY_AGE,
  FILTER_PROPERTY_TYPE,
  FILTER_PSF_RENTAL_6M,
  FILTER_SIZE,
  FILTER_TENURE,
  FILTER_TOTAL_UNITS,
  hasAnyValue,
  hasFilterSelected,
  hasMaxRangeSelected,
  hasMinRangeSelected,
  hasValidInput,
  initializeFilters
} from "@/utils/filter";
import Sidebar from "@/components/common/Sidebar";
import { convertMapUrl } from "@/utils/url";
import {
  calculateAgeYear,
  deepCopy,
  getPropTypeFilterSelected
} from "@/utils/convert";
import BlockMarker from "./map/BlockMarker";
import UpcomingMarker from "./map/UpcomingMarker";

const DEFAULT_PROPERTY_ZOOM = 16;
const DEFAULT_AREA_ZOOM = 12;
const DEFAULT_FULL_ZOOM = 10;
const MAX_CLUSTER_ZOOM = 18;
const MIN_CLUSTER_DENSE_ZOOM = 15;

// const MARKER_SCHEME_OPTIONS = [
//   {
//     id: 'profitable',
//     label: 'Profitable %'
//   }
// ];

const protocol = new Protocol();
addProtocol("pmtiles", protocol.tile);

const MainMap = ({
  user,
  target,
  focus,
  setFocus,
  mapMediaStyle,
  mapData,
  goToProperty,
  goToMarker,
  goToArea,
  goToStation,
  goToSchool,
  goToUpcoming,
  isMinimized,
  isMaximized,
  setLoading,
  setErr,
  closePropertyDetails,
  loadAdvMapData,
  hasAdvMapData,
  filterSelected,
  setFilterSelected,
  compareList,
  viewComparePro,
  hasSearchResults
}) => {
  const mapRef = useRef();

  const [allowInteraction, setAllowInteraction] = useState(true);
  // const [showZoomHint, setShowZoomHint] = useState(true);

  const [mapStyle, setMapStyle] = useState(DEFAULT_MAP_THEME);
  const [zoom, setZoom] = useState(DEFAULT_FULL_ZOOM);

  const [sideContent, setSideContent] = useState(null);

  // const [markerScheme, setMarkerScheme] = useState(MARKER_SCHEME_OPTIONS[0].id);

  const [isHoverMarker, setIsHoverMarker] = useState(false);
  const [tooltipContent, setTooltipContent] = useState(null);
  const [hiddenOverlayIds, setHiddenOverlayIds] = useState([]);
  const [overlayOpacity, setOverlayOpacity] = useState(0.5);

  /**
   * Property data (filtered)
   */
  const [filteredMarkers, setFilteredMarkers] = useState(mapData.markers);
  const [filteredAreaMarkers, setFilteredAreaMarkers] = useState(groupMarkerByArea(filteredMarkers));
  const [numFilters, setNumFilters] = useState(0);

  /**
   * Handle map clustering
   */
  const [clusters, setClusters] = useState([]);
  const [supercluster, setSupercluster] = useState(null);
  const [bounds, setBounds] = useState(null);
  
  useEffect(() => {
    const supercluster = new Supercluster({
      radius: 60,
      maxZoom: 18
    });
    supercluster.load(filteredMarkers.map(prop => ({
      type: 'Feature',
      properties: {
        ...prop,
        id: `${prop.name}`,
        cluster: false
      },
      geometry: {
        type: 'Point',
        coordinates: [prop.lng, prop.lat]
      }
    })));
    setSupercluster(supercluster);
  }, [filteredMarkers]);

  useEffect(() => {
    if (supercluster && bounds && zoom > DEFAULT_AREA_ZOOM) {
      // get all clusters within set bounds
      let clusters = supercluster.getClusters(bounds, zoom);
      
      if (zoom > MAX_CLUSTER_ZOOM) {
        // if hit max zoom for cluster, should flatten the cluster to locations and show unclustered
        clusters = clusters.flatMap(c => {
          // handle single location
          if (!c.properties.cluster) return [c];

          // handle cluster
          return supercluster.getLeaves(c.properties.cluster_id, Infinity);
        });
      } else {
        // if have not hit max zoom, allow clustering
        clusters = clusters
          .map(c => {
            // handle single location
            if (!c.properties.cluster) return c;

            // handle cluster
            const markers = supercluster.getLeaves(c.properties.cluster_id, Infinity)
              .map(l => l.properties);
            const projects = markers.flatMap(m => m.properties);
            const totalTx = projects.reduce((s, p) => s + (p.totalTx ?? 0), 0);
            const profitPair = projects.reduce((s, p) => [ s[0] + (p.profitable !== null ? p.profitable : 0), s[1] + (p.profitable !== null ? 1 : 0) ], [0, 0]);
            const profitable = profitPair[1] > 0 ? (profitPair[0] / profitPair[1]) : null;
            return {
              ...c,
              markers,
              projects,
              totalTx,
              profitable,
              lat: c.geometry.coordinates[1],
              lng: c.geometry.coordinates[0]
            };
          });
      }

      setClusters(clusters);
    }
  }, [supercluster, bounds]);
  
  const filterProperties = (props, filterSelected) => props.filter(p => {
    const areaFilter = new Set(Object.keys(filterSelected[FIELD_AREAS])
      .filter(area => filterSelected[FIELD_AREAS][area] && !isRegion(area)));

    const districtFilter = new Set(Object.keys(filterSelected[FIELD_DISTRICTS])
        .filter(district => filterSelected[FIELD_DISTRICTS][district]));

    const regionFilter = new Set(Object.keys(filterSelected[FIELD_REGIONS])
        .filter(region => filterSelected[FIELD_REGIONS][region])
        .map(region => MARKET_SEGMENT_ID_MAP[region]))

    if (hasFilterSelected(FILTER_PROPERTY_AGE, filterSelected)) {
      const hasMin = hasMinRangeSelected(FILTER_PROPERTY_AGE, filterSelected[FILTER_PROPERTY_AGE.field]);
      const age = calculateAgeYear(p.tenureDate, p.completion);
      if (hasMin) {
        const minAge = filterSelected[FILTER_PROPERTY_AGE.field].value.min;
        if (age === null || age < minAge) return false;
      }
      const hasMax = hasMaxRangeSelected(FILTER_PROPERTY_AGE, filterSelected[FILTER_PROPERTY_AGE.field]);
      if (hasMax) {
        const maxAge = filterSelected[FILTER_PROPERTY_AGE.field].value.max;
        if (age > maxAge) return false;
      }
    }

    if (hasFilterSelected(FILTER_AVG_PRICE, filterSelected)) {
      const hasMin = hasValidInput(filterSelected[FIELD_MIN_PRICE]);
      if (hasMin) {
        const minPrice = filterSelected[FIELD_MIN_PRICE];
        if (p.minPrice === null || p.minPrice < minPrice) return false;
      }
      const hasMax = hasValidInput(filterSelected[FIELD_MAX_PRICE]);
      if (hasMax) {
        const maxPrice = filterSelected[FIELD_MAX_PRICE];
        if (p.maxPrice === null || p.maxPrice > maxPrice) return false;
      }
    }

    if (hasFilterSelected(FILTER_AVG_UNIT_PRICE, filterSelected)) {
      const hasMin = hasValidInput(filterSelected[FIELD_MIN_UNIT_PRICE]);
      if (hasMin) {
        const minPsf = filterSelected[FIELD_MIN_UNIT_PRICE];
        if (p.avgPsf3m === null || p.avgPsf3m < minPsf) return false;
      }
      const hasMax = hasValidInput(filterSelected[FIELD_MAX_UNIT_PRICE]);
      if (hasMax) {
        const maxPsf = filterSelected[FIELD_MAX_UNIT_PRICE];
        if (p.avgPsf3m === null || p.avgPsf3m > maxPsf) return false;
      }
    }

    if (hasFilterSelected(FILTER_PROFITABLE, filterSelected)) {
      const hasMin = hasMinRangeSelected(FILTER_PROFITABLE, filterSelected[FILTER_PROFITABLE.field]);
      if (hasMin) {
        const minProfit = filterSelected[FILTER_PROFITABLE.field].value.min;
        if (p.profitable < 0 || p.profitable === null || p.profitable < minProfit) return false;
      }
      const hasMax = hasMaxRangeSelected(FILTER_PROFITABLE, filterSelected[FILTER_PROFITABLE.field]);
      if (hasMax) {
        const maxProfit = filterSelected[FILTER_PROFITABLE.field].value.max;
        if (p.profitable < 0 || p.profitable === null || p.profitable > maxProfit) return false;
      }
    }

    if (hasFilterSelected(FILTER_COMPLETION_DATE, filterSelected)) {
      const completedYear = !!p.completion ? parseInt(p.completion) : null;
      const minDate = filterSelected[FILTER_COMPLETION_DATE.field[0]];
      if (minDate !== null) {
        const startYear = minDate.getFullYear();
        if (!!startYear && completedYear < startYear) return false;
      }
      const maxDate = filterSelected[FILTER_COMPLETION_DATE.field[1]];
      if (maxDate !== null) {
        const endYear = maxDate.getFullYear();
        if (!!endYear && (!completedYear || completedYear > endYear)) return false;
      }
    }

    if (hasFilterSelected(FILTER_PROPERTY_TYPE, filterSelected)) {
      const propFilter = getPropTypeFilterSelected(filterSelected, FILTER_PROPERTY_TYPE);
      const types = new Set(p.types);
      if (!propFilter.some(t => types.has(t))) return false;
    }

    if (hasFilterSelected(FILTER_TENURE, filterSelected)) {
      const tenureFilter = Object.keys(filterSelected[FILTER_TENURE.field])
        .filter(o => filterSelected[FILTER_TENURE.field][o])
        .map(o => getTenureValue(o));
      const types = new Set(p.tenures.map(t => t.includes(' yrs ') ? parseInt(t.split(' yrs ')[0]).toString() : t));
      if (!tenureFilter.some(t => types.has(t))) return false;
    }

    if (hasFilterSelected(FILTER_SIZE, filterSelected)) {
      const hasMin = hasValidInput(filterSelected[FIELD_MIN_SIZE]);
      if (hasMin) {
        const minSize = filterSelected[FIELD_MIN_SIZE];
        if (p.minSize < minSize) return false;
      }
      const hasMax = hasValidInput(filterSelected[FIELD_MAX_SIZE]);
      if (hasMax) {
        const maxSize = filterSelected[FIELD_MAX_SIZE];
        if (p.maxSize > maxSize) return false;
      }
    }

    if (hasFilterSelected(FILTER_LOCATION, filterSelected)) {
      if (hasAnyValue(filterSelected[FIELD_AREAS])) {
        if (!p.areas.some(a => areaFilter.has(a))) return false;
      } else if (hasAnyValue(filterSelected[FIELD_DISTRICTS])) {
        if (!districtFilter.has(p.district)) return false;
      } else if (hasAnyValue(filterSelected[FIELD_REGIONS])) {
        if (!regionFilter.has(p.marketSegment)) return false;
      }
    }

    if (hasFilterSelected(FILTER_MAP_TRANSACTION_DATE, filterSelected)) {
      const minDate = filterSelected[FILTER_MAP_TRANSACTION_DATE.field[0]];
      if (minDate !== null) {
        if (p.lastTxDate < minDate) return false;
      }
      const maxDate = filterSelected[FILTER_MAP_TRANSACTION_DATE.field[1]];
      if (maxDate !== null) {
        if (p.lastTxDate > maxDate) return false;
      }
    }

    if (hasFilterSelected(FILTER_HDB_BUYERS, filterSelected)) {
      const hasMin = hasMinRangeSelected(FILTER_HDB_BUYERS, filterSelected[FILTER_HDB_BUYERS.field]);
      if (hasMin) {
        const minVal = filterSelected[FILTER_HDB_BUYERS.field].value.min;
        if (p.hdbBuyers < minVal) return false;
      }
      const hasMax = hasMaxRangeSelected(FILTER_HDB_BUYERS, filterSelected[FILTER_HDB_BUYERS.field]);
      if (hasMax) {
        const maxVal = filterSelected[FILTER_HDB_BUYERS.field].value.max;
        if (p.hdbBuyers > maxVal) return false;
      }
    }

    if (hasFilterSelected(FILTER_TOTAL_UNITS, filterSelected)) {
      const hasMin = hasMinRangeSelected(FILTER_TOTAL_UNITS, filterSelected[FILTER_TOTAL_UNITS.field]);
      if (hasMin) {
        const minVal = filterSelected[FILTER_TOTAL_UNITS.field].value.min;
        if (p.totalUnits < minVal) return false;
      }
      const hasMax = hasMaxRangeSelected(FILTER_TOTAL_UNITS, filterSelected[FILTER_TOTAL_UNITS.field]);
      if (hasMax) {
        const maxVal = filterSelected[FILTER_TOTAL_UNITS.field].value.max;
        if (p.totalUnits > maxVal) return false;
      }
    }

    if (hasFilterSelected(FILTER_LAUNCH_PERC, filterSelected)) {
      const hasMin = hasMinRangeSelected(FILTER_LAUNCH_PERC, filterSelected[FILTER_LAUNCH_PERC.field]);
      if (hasMin) {
        const minVal = filterSelected[FILTER_LAUNCH_PERC.field].value.min;
        if (p.soldAtLaunch < minVal) return false;
      }
      const hasMax = hasMaxRangeSelected(FILTER_LAUNCH_PERC, filterSelected[FILTER_LAUNCH_PERC.field]);
      if (hasMax) {      
        const maxVal = filterSelected[FILTER_LAUNCH_PERC.field].value.max;
        if (p.soldAtLaunch > maxVal) return false;
      }
    }

    if (hasFilterSelected(FILTER_PSF_RENTAL_6M, filterSelected)) {
      const hasMin = hasMinRangeSelected(FILTER_PSF_RENTAL_6M, filterSelected[FILTER_PSF_RENTAL_6M.field]);
      if (hasMin) {
        const minVal = filterSelected[FILTER_PSF_RENTAL_6M.field].value.min;
        if (p.last6mAvgRentPsf < minVal) return false;
      }
      const hasMax = hasMaxRangeSelected(FILTER_PSF_RENTAL_6M, filterSelected[FILTER_PSF_RENTAL_6M.field]);
      if (hasMax) {
      const maxVal = filterSelected[FILTER_PSF_RENTAL_6M.field].value.max;
        if (p.last6mAvgRentPsf > maxVal) return false;
      }
    }
    
    return true;
  });

  useEffect(() => {
    if (focus && focus.target && focus.target.lat && focus.target.lng) {
      if (focus.links && focus.links.length > 0) {
        setTimeout(() => {
          const coords = [focus.target, ...focus.links];
          const bounds = new LngLatBounds();
          coords.forEach(c => bounds.extend([c.lng, c.lat]));
          mapRef.current.fitBounds(bounds, { padding: checkMediaQuery() ? 88 : 188 });
          setTimeout(() => {
            const currentZoom = mapRef.current.getZoom();
            if (currentZoom <= DEFAULT_AREA_ZOOM)
              flyTo([focus.target.lng, focus.target.lat], DEFAULT_AREA_ZOOM + 0.1);
          }, 688);
        }, 188);
      } else {
        setTimeout(() => {
          if (focus.type === LOCATION_AREA) {
            flyTo([focus.target.lng, focus.target.lat], zoom);
          } else {
            flyTo([focus.target.lng, focus.target.lat]);
          }
        }, 188);
      }
    }
  }, [focus]);

  /* Handle map interaction */
  const onMapMoveEnd = () => {
    if (mapRef.current) {
      const bounds = mapRef.current.getBounds();
      const fraction = zoom < MIN_CLUSTER_DENSE_ZOOM ? 0 : 0.8;
      const latDiff = (bounds.getNorth() - bounds.getSouth()) * fraction;
      const lngDiff = (bounds.getEast() - bounds.getWest()) * fraction;
      setBounds([
        bounds.getWest() - lngDiff,
        bounds.getSouth() - latDiff,
        bounds.getEast() + lngDiff,
        bounds.getNorth() + latDiff
      ]);

      const currZoom = mapRef.current.getZoom();
      if (currZoom != zoom) setZoom(currZoom);
    }
    setAllowInteraction(true);
  };

  const onDebouncedZoom = useCallback(debounce(() => {
    const zoomLevel = mapRef.current.getZoom();
    setZoom(zoomLevel);
  }, DEBOUNCE_TIMING), []);

  const onMapZoom = () => {
    if (allowInteraction) {
      onDebouncedZoom();
    }
  };

  const flyTo = (coordinates, zoom = DEFAULT_PROPERTY_ZOOM) => {
    const map = mapRef.current?.getMap();
    if (!map) return;
    setAllowInteraction(false);

    map.flyTo({
      center: coordinates,
      essential: true,
      zoom: zoom,
      transitionDuration: 388
    });
  };

  const closeSideBar = () => {
    if (document.getElementById('mapSidebar').classList.contains('show')) {
      document.getElementById('mapside-close-button').click();
    }
  };

  const applyData = (data) => {
    const filteredProps = filterProperties(data, filterSelected);
    const filteredMarkers = groupPropsByMarker(filteredProps);
    setFilteredMarkers(filteredMarkers);
    setFilteredAreaMarkers(groupMarkerByArea(filteredMarkers));
  };

  const onApplyFilter = () => {
    const requireAdvData = hasFilterSelected(FILTER_PROPERTY_AGE, filterSelected)
      || hasFilterSelected(FILTER_AVG_PRICE, filterSelected)
      || hasFilterSelected(FILTER_SIZE, filterSelected)
      || hasFilterSelected(FILTER_MAP_TRANSACTION_DATE, filterSelected)
      || hasFilterSelected(FILTER_HDB_BUYERS, filterSelected)
      || hasFilterSelected(FILTER_TOTAL_UNITS, filterSelected)
      || hasFilterSelected(FILTER_LAUNCH_PERC, filterSelected)
      || hasFilterSelected(FILTER_PSF_RENTAL_6M, filterSelected)
      || hasFilterSelected(FILTER_AVG_UNIT_PRICE, filterSelected);

    if (requireAdvData && !hasAdvMapData) {
      loadAdvMapData((newData) => {
        applyData(newData);
      });
    } else {
      applyData(mapData.projects);
    }
    closeSideBar();

    // apply the number of filters
    const filtersApplied = MAP_FILTER_OPTIONS.reduce((s, opt) => s + (hasFilterSelected(opt, filterSelected) ? 1 : 0), 0);
    if (numFilters !== filtersApplied) {
      setNumFilters(filtersApplied);
    }
  };

  const onResetFilter = () => {
    const newFilter = initializeFilters(MAP_FILTER_OPTIONS, new URLSearchParams());
    setFilterSelected(newFilter);
    setFilteredMarkers(mapData.markers);
    setFilteredAreaMarkers(groupMarkerByArea(mapData.markers));
    convertMapUrl(null);

    // update number of filters
    if (numFilters > 0) {
      setNumFilters(0);
    }
  };

  useEffect(() => {
    const map = mapRef.current?.getMap();
    if (map && map.getLayer("pmtiles-layer")) {
      map.setPaintProperty("pmtiles-layer", "fill-opacity", getOverlaysOpacity(hiddenOverlayIds, overlayOpacity));
    }
  }, [overlayOpacity, hiddenOverlayIds]);

  const onMouseHover = (evt) => {
    const features = evt.target.queryRenderedFeatures(evt.point, {
      layers: [
        'pmtiles-layer',
        'school-layer',
        'station-layer'
      ],
    });

    if (features.length > 0) {
      const feature = features[0];
      const { x, y } = evt.point;
      const { lng, lat } = evt.lngLat;
      const layer = feature.layer.id;
      let label = null;
      if (layer === 'pmtiles-layer') {
        if (hiddenOverlayIds.indexOf(feature.properties.id) >= 0) return;
        label = OVERLAY_TYPES_MAP[feature.properties.id];
      } else if (layer === 'school-layer') {
        const data = JSON.parse(feature.properties.data);
        label = data.label;
      } else if (layer === 'station-layer') {
        const data = JSON.parse(feature.properties.data);
        label = data.name;
      }
      if (label) {
        setTooltipContent({ layer, x, y, lat, lng, feature, label });
      }
    } else if (tooltipContent) {
      setTooltipContent(null);
    }
  };

  const onMouseClick = (evt) => {
    const map = mapRef.current?.getMap();
    if (!map) return;

    const features = map.queryRenderedFeatures(evt.point, {
      layers: [
        'school-layer',
        'station-layer'
      ],
    });

    if (features.length) {
      const feature = features[0];
      const layer = feature.layer.id;
      if (layer === 'school-layer') {
        goToSchool(JSON.parse(feature.properties.data));
      } else if (layer === 'station-layer') {
        goToStation(JSON.parse(feature.properties.data));
      }
    }
  };

  const fetchAndAddImage = async (imgId, url) => {
    const response = await fetch(url);
    const blob = await response.blob();
    const imageBitmap = await createImageBitmap(blob);
    mapRef.current?.getMap()?.addImage(imgId, imageBitmap);
  };

  const allowRecenter = target && target.type === LOCATION_PROPERTY
    && (mapRef.current?.getCenter()?.lat?.toFixed(5) !== mapData.projects[mapData.projectIndex[`${target?.id}_${target?.projectId}`]]?.lat?.toFixed(5)
      || mapRef.current?.getCenter()?.lng?.toFixed(5) !== mapData.projects[mapData.projectIndex[`${target?.id}_${target?.projectId}`]]?.lng?.toFixed(5));

  return (
    <>
      {/* for disabling map interaction while moving */}
      {!allowInteraction && (
        <div
          style={{
            ...mapMediaStyle,
            position: 'absolute',
            top: '132px',
            left: 0,
            backgroundColor: 'rgba(0, 0, 0, 0)',
            zIndex: 10,
            overflow: 'hidden'
          }}
        />
      )}

      {/* map */}
      <Map
        ref={mapRef}
        maxBounds={[103.3, 1.0, 104.3, 1.7]}
        mapStyle={getMapStyleFromTheme(mapStyle)}
        style={mapMediaStyle}
        initialViewState={{
          longitude: 103.831833,
          latitude: 1.304833,
          zoom: DEFAULT_FULL_ZOOM
        }}
        attributionControl={false}
        transformRequest={transformMapboxStyleRequest}
        maxZoom={18}
        onMoveEnd={onMapMoveEnd}
        onZoom={onMapZoom}
        onMouseMove={onMouseHover}
        onMouseLeave={() => setTooltipContent(null)}
        onClick={onMouseClick}
        onLoad={evt => {
          const map = evt.target;

          // load images for symbol layers
          fetchAndAddImage('ic-mrt', '/img/general/ic_mrt.png');
          fetchAndAddImage('ic-lrt', '/img/general/ic_lrt.png');
          fetchAndAddImage('ic-school', '/img/general/school.png');

          // load pmtiles for overlay
          const pmtilesUrl = "https://realsmart.global.ssl.fastly.net/t/lul.pmtiles";
          const pmtiles = new PMTiles(pmtilesUrl);

          pmtiles.getHeader().then(() => {
            map.addSource("pmtiles-source", {
              type: "vector",
              tiles: [`pmtiles://${pmtilesUrl}/{z}/{x}/{y}.pbf`],
            });
    
            // Add a layer to render the PMTiles data
            map.addLayer({
              id: "pmtiles-layer",
              type: "fill",
              source: "pmtiles-source",
              "source-layer": "landuse",
              paint: {
                'fill-color': getOverlayFill(),
                "fill-opacity": getOverlaysOpacity(hiddenOverlayIds, overlayOpacity),
              },
            }, 'school-layer');
          });
        }}
      >

        {/* radius circle overlay */}
        {focus?.target && focus?.type !== LOCATION_AREA // && zoom > DEFAULT_AREA_ZOOM
          && <>
            <RadiusOverlay
              target={focus.target}
              radiusKm={1}
            />
            <RadiusOverlay
              target={focus.target}
              radiusKm={2}
            />
          </>
        }

        {/* area markers */}
        {zoom <= DEFAULT_AREA_ZOOM && filteredAreaMarkers.map((a, i) =>
          <AreaMarker
            idx={i}
            area={a}
            onClick={() => goToArea(a)}
            onMouseEnter={() => setIsHoverMarker(true)}
            onMouseLeave={() => setIsHoverMarker(false)}
            zoom={zoom}
          />
        )}

        {zoom > DEFAULT_AREA_ZOOM
          && <>
            {/* cluster markers */}
            {clusters.filter(c => c.properties.cluster).map((c, i) =>
              <AreaMarker
                idx={i}
                area={c}
                onClick={() => goToArea({ ...c, isCluster: true })}
                onMouseEnter={() => setIsHoverMarker(true)}
                onMouseLeave={() => setIsHoverMarker(false)}
                isCluster
              />
            )}

            {/* project markers */}
            {clusters.filter(c => !c.properties.cluster).map((c, i) =>
              <PropertyMarker
                idx={i}
                target={target}
                property={c}
                goToMarker={goToMarker}
                onMouseEnter={() => setIsHoverMarker(true)}
                onMouseLeave={() => setIsHoverMarker(false)}
              />
            )}

            {/* upcoming project markers */}
            {mapData.upcomings.map((u, i) =>
              <UpcomingMarker
                idx={i}
                property={u}
                goToProperty={goToUpcoming}
                onMouseEnter={() => setIsHoverMarker(true)}
                onMouseLeave={() => setIsHoverMarker(false)}
              />
            )}
          </>
        }

        {/* block markers */}
        {focus?.target?.type === LOCATION_BLOCK
          && <BlockMarker
            idx={888}
            target={focus?.target}
          />
        }
        {focus?.links?.length > 0 && focus?.links?.some(l => l.type === LOCATION_BLOCK)
          && focus.links.map((l, i) =>
            <BlockMarker
              idx={i}
              target={l}
            />
          )
        }

        {/* lines */}
        {focus?.links?.length > 0
          && <Source id="highlight-path-block-prop" type="geojson" data={generateLinesData(focus.target, focus.links)}>
            <Layer
              {...{
                id: 'route',
                type: 'line',
                paint: {
                  'line-color': '#0000ff',
                  'line-width': 2,
                  'line-dasharray': [2, 2],
                }
              }}
            />
          </Source>
        }

        {tooltipContent && !isHoverMarker && !target
          && <div
            style={{
              position: "absolute",
              left: tooltipContent.x + 6,
              top: tooltipContent.y + 6,
              backgroundColor: "white",
              padding: "5px 10px",
              borderRadius: "6px",
              pointerEvents: "none",
              fontWeight: "600"
            }}
          >
            {tooltipContent.label}
          </div>
        }

        {/* schools overlay */}
        <Source
          id="school-markers"
          type="geojson"
          data={{
            type: 'FeatureCollection',
            features: mapData.schools.map((school) => ({
              type: 'Feature',
              properties: {
                id: school.postal,
                data: school,
                label: school.label,
              },
              geometry: { type: 'Point', coordinates: [school.lng, school.lat] },
            })),
          }}
        >
          <Layer
            id="school-layer"
            type="symbol"
            minzoom={DEFAULT_AREA_ZOOM}
            layout={{
              'icon-image': 'ic-school',
              'icon-size': 0.6,
              'icon-allow-overlap': true,
              'icon-ignore-placement': true,
            }}
          />
        </Source>

        {/* stations overlay */}
        <Source
          id="station-markers"
          type="geojson"
          data={{
            type: 'FeatureCollection',
            features: mapData.stations.map((station) => ({
              type: 'Feature',
              properties: {
                id: station.id,
                data: station,
                label: station.exit,
                type: station.subtype
              },
              geometry: { type: 'Point', coordinates: [station.lng, station.lat] },
            })),
          }}
        >
          <Layer
            id="station-layer"
            type="symbol"
            minzoom={DEFAULT_AREA_ZOOM}
            layout={{
              'icon-image': [
                'match',
                ['get', 'type'],
                'mrt', 'ic-mrt',
                'lrt', 'ic-lrt',
                'ic-mrt'
              ],
              'icon-size': 0.9,
              'text-field': [
                'step', ['zoom'],
                '', 14, ['get', 'label']
              ],
              'text-size': 8,
              'text-font': ['Open Sans Bold'],
              'text-offset': [0, 1.8],
              'text-anchor': 'top',
              'icon-allow-overlap': true,
              'text-allow-overlap': true,
              'icon-ignore-placement': true,
              'text-ignore-placement': true,
            }}
            paint={{
              'text-color': '#000000',
              'text-halo-color': '#FFFFFF',
              'text-halo-width': 8,
            }}
          />
        </Source>

      </Map>

      {/* map buttons */}
      <div className="proj-map-bars noselect px-5" style={{ width: mapMediaStyle.width }}>
        <MapButtons
          theme={mapStyle}
          setTheme={setMapStyle}
          onSelectFilter={(content) => setSideContent(content)}
          // markerSchemeOptions={MARKER_SCHEME_OPTIONS}
          // markerScheme={markerScheme}
          // setMarkerScheme={setMarkerScheme}
          overlayOpacity={overlayOpacity}
          setOverlayOpacity={setOverlayOpacity}
          hiddenOverlayIds={hiddenOverlayIds}
          setHiddenOverlayIds={setHiddenOverlayIds}
          onSelectCompare={viewComparePro}
          numFilters={numFilters}
        />
        
        {/* {showZoomHint && zoom <= DEFAULT_AREA_ZOOM
          && <div className="container container-map-hint">
            <div className="alert alert-info text-14 text-center py-5 d-flex" role="alert">
              <span className="p-2 flex-grow-1">Zoom in to see properties instead of area clusters</span>
              <button
                className="p-2 button mr-10 text-blue-1 text-12"
                onClick={() => {
                  const map = mapRef.current?.getMap();
                  if (map) flyTo(map.getCenter(), DEFAULT_AREA_ZOOM + 0.1);
                }}
              >ZOOM IN</button>
              <button
                className="p-2 button ml-10 text-blue-1 text-12"
                onClick={() => setShowZoomHint(false)}
              >
                <i className="icon-close" />
              </button>
            </div>
          </div>
        } */}

      </div>

      {!isMaximized && !hasSearchResults
        && <div
          className="proj-map-set noselect d-flex justify-content-center"
          style={{
            width: mapMediaStyle?.width,
            ...(checkMediaQuery() ? { bottom: (target && !isMinimized) ? '49svh' : '66px' } : {})
          }}
        >
          {/* {Object.keys(compareList).length > 0 && target?.type !== LOCATION_COMPARE
            && <button
              className="p-2 button -sm -dark-1 bg-white text-blue-1 border-light rounded-100 text-12 px-10 py-5 mr-5"
              onClick={() => viewComparePro()}
            >
              <i className="icon-award text-15 mr-5" />
              Compare PRO
            </button>
          } */}
          {focus && focus.target?.id !== target?.id && focus.target?.type !== LOCATION_UPCOMING
            && <button
              className="p-2 button -sm -dark-1 bg-white text-blue-1 border-light rounded-100 text-12 px-10 py-5 mr-5"
              onClick={() => setFocus(null)}
            >
              Clear Selection
              <i className="icon-close ml-5 text-10" />
            </button>
          }
          {allowRecenter
            && <button
              className="p-2 button -sm -dark-1 bg-white text-blue-1 border-light rounded-100 text-12 px-10 py-5"
              onClick={() => {
                const d = mapData.projects[mapData.projectIndex[getProjectKeyByTarget(target)]];
                flyTo([d.lng, d.lat], DEFAULT_PROPERTY_ZOOM);
              }}
            >
              <i className="icon-location text-15 mr-5" />
              Re-center
            </button>
          }
        </div>
      }

      {/* overall map tooltip */}
      <Tooltip id="map-tooltip" style={{ zIndex: 900 }} />
      <Tooltip id="area-tooltip" />
      <Tooltip id="cluster-tooltip" />
      <Tooltip id="prop-tooltip" />
      <Tooltip id="block-tooltip" />
      <Tooltip id="upcoming-tooltip" />

      {/* filter and info side panel */}
      <div
        className={`offcanvas offcanvas-start filter-bar-nopad ${noSelectClass(user)}`}
        tabIndex="-1"
        id="mapSidebar"
      >
        <div className="offcanvas-header">
          <h5 className="offcanvas-title" id="offcanvasLabel">
            {sideContent?.type === 'filter' ? 'Filter Properties' : ''}
          </h5>
          <button
            id="mapside-close-button"
            type="button"
            className="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>

        {/* Filter */}
        {sideContent?.type === 'filter'
          && <>
            <div className="offcanvas-body">
              <aside className="sidebar y-gap-40 xl:d-block">
                <Sidebar
                  options={MAP_FILTER_OPTIONS}
                  filters={filterSelected}
                  setFilters={setFilterSelected}
                />
              </aside>
            </div>

            <div className="row ml-10 mr-10 mt-10 mb-10">
              <div className="col-3">
                <button
                  className="button -dark-1 py-15 px-40 h-50 col-12 rounded-0 bg-red-1 text-white w-100"
                  onClick={onResetFilter}
                >
                  Reset
                </button>
              </div>
              <div className="col-9">
                <button
                  className="button -dark-1 py-15 px-40 h-full col-12 rounded-0 bg-blue-1 text-white w-100"
                  onClick={onApplyFilter}
                >
                  Apply Filter
                </button>
              </div>
            </div>
          </>
        }

        {/* Compare */}

      </div>
    </>
  );
};

export default MainMap;
