import { useEffect, useRef, useState } from "react";
import { Dropdown } from "bootstrap";
import {
  LOCATION_PROPERTY,
  LOCATION_SCHOOL,
  LOCATION_STATION
} from "@/utils/areas";
import { getProjectLabel } from "@/utils/map";

const LocationSearch = ({
  searchValue,
  results,
  onInput,
  onSelect,
  onKeyDown,
  hint,
  selectedLocation = null,
  onClearSelectedLocation = null
}) => {
  const dropdownToggleRef = useRef(null);
  const dropdownInstanceRef = useRef(null);

  const [rowHighlight, setRowHighlight] = useState(null);

  useEffect(() => {
    if (dropdownToggleRef.current) {
      dropdownInstanceRef.current = new Dropdown(dropdownToggleRef.current);
    }
  }, []);

  useEffect(() => {
    if (searchValue && dropdownInstanceRef.current) {
      // Show dropdown when input is typed
      dropdownInstanceRef.current.show();
    }
  }, [searchValue]);

  const onSearchResults = () => {
    onKeyDown();
  };

  const onInputKeyDown = (e) => {
    if (e.key === 'Enter') {
      if (rowHighlight !== null) onSelect(results[rowHighlight]);
      else onSearchResults();
    } else if ((e.key === 'ArrowUp' || e.key === 'Up') && results.length > 0) {
      const lastIdx = results.length - 1;
      const newIdx = rowHighlight === null ? lastIdx : (rowHighlight > 0 ? (rowHighlight - 1) : lastIdx);
      setRowHighlight(newIdx);
      scrollToRow(newIdx);
    } else if ((e.key === 'ArrowDown' || e.key === 'Down') && results.length > 0) {
      const newIdx = rowHighlight === null ? 0 : (rowHighlight + 1) % results.length;
      setRowHighlight(newIdx);
      scrollToRow(newIdx);
    }
  };

  const scrollToRow = (idx) => {
    const row = document.getElementById(`project-search-result-${idx}`);
    if (row) {
      row.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'nearest'
      });
    }
  };

  const onInputChange = (e) => {
    onInput(e.target.value);
    setRowHighlight(null);
  };

  const getItemIcon = (item) => {
    if (item.type === LOCATION_PROPERTY) return 'home';
    if (item.type === LOCATION_SCHOOL) return 'edu';
    if (item.type === LOCATION_STATION) return 'train';
    return 'home';
  };

  const formatSubtypeLabel = (item) => {
    if (item.type === LOCATION_SCHOOL) {
      return item.subtype.map(t => {
        if (t === 'Primary' || t === 'Secondary') return `${t} School`;
        if (t === 'Post Secondary') return `${t} Education`;
        return t;
      }).join(', ');
    } else if (item.type === LOCATION_STATION) {
      return item.subtype.map(t => `${t} Station`).join(', ');
    }
    return item.subtype.join(', ');
  };

  const getDisplayLabel = (item) => {
    if (item.type === LOCATION_PROPERTY) {
      return getProjectLabel(item.marker, item.names[0]);
    }
    return item.names
      .filter(name => !name.includes('MOE KINDERGARTEN @'))
      .join(', ');
  };

  return (
    <>
      <div
        className="searchMenu-loc px-10 py-0 bg-white rounded-4 js-form-dd js-liverSearch"
        onScroll={e => e.stopPropagation()}
        onWheel={e => e.stopPropagation()}
        onTouchMove={e => e.stopPropagation()}
      >
        <div
          ref={dropdownToggleRef}
          data-bs-toggle="dropdown"
          data-bs-auto-close="true"
          data-bs-offset="0,22"
        >
          <div className="text-15 text-light-1 ls-2 lh-16">
            {!selectedLocation
              && <input
                autoComplete="off"
                type="search"
                placeholder={hint ?? "Enter project, street, postal code, school, MRT or LRT station"}
                className="js-search js-dd-focus"
                value={searchValue}
                onChange={(e) => onInputChange(e)}
                onKeyDown={onInputKeyDown}
              />
            }
            {selectedLocation
              && <div className="d-flex py-0">
                <span className="p-2 flex-grow-1 py-0">{selectedLocation}</span>
                <button
                  className="p-2 py-0 px-5"
                  onClick={onClearSelectedLocation}
                >
                  <i className="icon-close" />
                </button>
              </div>
            }
          </div>
        </div>

        <div className="shadow-2 dropdown-menu propsearch-dropdown">
          {searchValue
            && <>
              {
                // !loading &&
                searchValue.length > 2
                && <div className="bg-white px-10 py-10 sm:px-0 sm:py-15 rounded-4 project-search-dropdown">
                  {!results?.length
                    && <p className="text-15 px-20 py-10">No results found</p>
                  }
                  <ul className="y-gap-5 js-results pt-10">
                    {results.map((item, idx) => (
                      <li
                        id={`project-search-result-${idx}`}
                        className={`-link d-block col-12 text-left rounded-4 px-10 py-10 js-search-option mb-1 ${rowHighlight === idx ? 'bg-blue-2' : ''}`}
                        key={idx}
                        role="button"
                        onClick={() => onSelect(item)}
                      >
                        <div className="d-flex text-14">
                          <img className="search-ic mr-10" src={`/img/general/icons/ic_${getItemIcon(item)}.png`} />
                          <div className="ml-10">
                            <div className="lh-12 fw-500 js-search-option-target">
                              {getDisplayLabel(item)}
                            </div>
                            <div className="text-12 lh-12 text-blue-1 mt-5">
                              {formatSubtypeLabel(item)}
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              }
            </>
          }
        </div>
      </div>
    </>
  );
};

export default LocationSearch;
