import PropTypes from "prop-types";

import {
  convertTHeaderToApiCol,
  getPropTypeFilterSelected,
  includeProjectTHeader,
  includeStreetTHeader
} from "@/utils/convert";
import { TRANSACTIONS_TABLE_HEADERS } from "@/utils/transactionHeaders";
import {
  getTransactionsTable,
  logTransactionSearchFilter
} from "@/utils/api";
import {
  MARKET_SEGMENT_ID_MAP,
  getTenureValue,
  isRegion
} from "@/utils/areas";
import {
  FIELD_AREAS,
  FIELD_DISTRICTS,
  FIELD_END_DATE,
  FIELD_MAX_PRICE,
  FIELD_MAX_SIZE,
  FIELD_MAX_UNIT_PRICE,
  FIELD_MIN_PRICE,
  FIELD_MIN_SIZE,
  FIELD_MIN_UNIT_PRICE,
  FIELD_NAME,
  FIELD_REGIONS,
  FIELD_START_DATE,
  FILTER_AGE,
  FILTER_DATE,
  FILTER_LEASE,
  FILTER_LOCATION,
  FILTER_PRICE,
  FILTER_PROPERTY_INPUT,
  FILTER_PROPERTY_TYPE,
  FILTER_FULL_SALE_TYPE,
  FILTER_SIZE,
  FILTER_TENURE,
  FILTER_TRANSACTION_DATE,
  FILTER_UNCOMPLETED,
  FILTER_UNIT_PRICE,
  getTenureValues,
  hasAnyValue,
  hasFilterSelected,
  hasMaxRangeSelected,
  hasMinRangeSelected,
  hasValidInput,
  isValidDate
} from "@/utils/filter";
import { getCurrentYear, timestampFromYear } from "@/utils/time";
import TableSearchPage from "../tablepage";

const metadata = {
  title: "Transaction Search | REALSMART.SG | Supercharge your property search",
  description: "REALSMART.SG - Supercharge your property search",
};

const DEFAULT_COLUMNS = TRANSACTIONS_TABLE_HEADERS;
const REQUIRED_COLUMNS = includeStreetTHeader(includeProjectTHeader(convertTHeaderToApiCol(DEFAULT_COLUMNS)));

const FILTER_OPTIONS = [
  // FILTER_PROPERTY_INPUT,
  FILTER_TRANSACTION_DATE,
  FILTER_PROPERTY_TYPE,
  FILTER_FULL_SALE_TYPE,
  FILTER_TENURE,
  FILTER_LEASE,
  FILTER_UNCOMPLETED,
  FILTER_AGE,
  FILTER_PRICE,
  FILTER_UNIT_PRICE,
  FILTER_SIZE,
  FILTER_LOCATION,
];

const GROUP_OPTIONS = [
  { label: 'Project', value: 'project', options: ['Project Name', 'Street'], accessors: ['id'] },
  { label: 'Address', value: 'address', options: ['Address'], accessors: ['Address'] },
  { label: 'Sale Type', value: 'project_sale', options: ['Type of Sale'], accessors: ['Type of Sale'] },
];

const DEFAULT_SORT_ORDER = {
  'Sale Date': 'desc',
  'count': 'desc',
};

const TransactionSearchPage = ({ user, session }) => {
  const createFilter = (filterSelected) => {
    const filters = {}

    if (hasFilterSelected(FILTER_UNCOMPLETED, filterSelected)) {
      filters['Completion Date'] = ':ne:Uncompleted';
    }

    // set date filters
    if (hasFilterSelected(FILTER_DATE, filterSelected)) {
      if (isValidDate(filterSelected[FIELD_START_DATE]) && isValidDate(filterSelected[FIELD_END_DATE])) {
        filters['Sale Date'] = `d:bt:${filterSelected[FIELD_START_DATE].getTime()}:${filterSelected[FIELD_END_DATE].getTime()}`;
      } else if (isValidDate(filterSelected[FIELD_START_DATE])) {
        filters['Sale Date'] = `d:gte:${filterSelected[FIELD_START_DATE].getTime()}`;
      } else {
        filters['Sale Date'] = `d:lte:${filterSelected[FIELD_END_DATE].getTime()}`;
      }
    }

    if (hasFilterSelected(FILTER_PROPERTY_TYPE, filterSelected)) {
      filters['Property Type'] = getPropTypeFilterSelected(filterSelected, FILTER_PROPERTY_TYPE);
    }

    if (hasFilterSelected(FILTER_FULL_SALE_TYPE, filterSelected)) {
      filters['Type of Sale'] = Object.keys(filterSelected[FILTER_FULL_SALE_TYPE.field])
        .filter(o => filterSelected[FILTER_FULL_SALE_TYPE.field][o]);
    }

    if (hasFilterSelected(FILTER_TENURE, filterSelected)) {
      filters['tenure_years'] = Object.keys(filterSelected[FILTER_TENURE.field])
        .filter(o => filterSelected[FILTER_TENURE.field][o])
        .map(o => getTenureValue(o));
    }

    if (hasFilterSelected(FILTER_PRICE, filterSelected)) {
      const hasMin = hasValidInput(filterSelected[FIELD_MIN_PRICE]);
      const hasMax = hasValidInput(filterSelected[FIELD_MAX_PRICE]);
      if (hasMin && hasMax) {
        filters['Transacted Price ($)'] = `i:bt:${filterSelected[FIELD_MIN_PRICE]}:${filterSelected[FIELD_MAX_PRICE]}`;
      } else if (hasMin) {
        filters['Transacted Price ($)'] = `i:gte:${filterSelected[FIELD_MIN_PRICE]}`;
      } else {
        filters['Transacted Price ($)'] = `i:lte:${filterSelected[FIELD_MAX_PRICE]}`;
      }
    }

    if (hasFilterSelected(FILTER_UNIT_PRICE, filterSelected)) {
      const hasMin = hasValidInput(filterSelected[FIELD_MIN_UNIT_PRICE]);
      const hasMax = hasValidInput(filterSelected[FIELD_MAX_UNIT_PRICE]);
      if (hasMin && hasMax) {
        filters['Unit Price ($ PSF)'] = `i:bt:${filterSelected[FIELD_MIN_UNIT_PRICE]}:${filterSelected[FIELD_MAX_UNIT_PRICE]}`;
      } else if (hasMin) {
        filters['Unit Price ($ PSF)'] = `i:gte:${filterSelected[FIELD_MIN_UNIT_PRICE]}`;
      } else {
        filters['Unit Price ($ PSF)'] = `i:lte:${filterSelected[FIELD_MAX_UNIT_PRICE]}`;
      }
    }

    if (hasFilterSelected(FILTER_SIZE, filterSelected)) {
      const hasMin = hasValidInput(filterSelected[FIELD_MIN_SIZE]);
      const hasMax = hasValidInput(filterSelected[FIELD_MAX_SIZE]);
      if (hasMin && hasMax) {
        filters['Area (SQFT)'] = `i:bt:${filterSelected[FIELD_MIN_SIZE]}:${filterSelected[FIELD_MAX_SIZE]}`;
      } else if (hasMin) {
        filters['Area (SQFT)'] = `i:gte:${filterSelected[FIELD_MIN_SIZE]}`;
      } else {
        filters['Area (SQFT)'] = `i:lte:${filterSelected[FIELD_MAX_SIZE]}`;
      }
    }

    if (hasFilterSelected(FILTER_LOCATION, filterSelected)) {
      if (hasAnyValue(filterSelected[FIELD_AREAS])) {
        filters['Planning Area'] = Object.keys(filterSelected[FIELD_AREAS])
          .filter(area => filterSelected[FIELD_AREAS][area] && !isRegion(area));
      } else if (hasAnyValue(filterSelected[FIELD_DISTRICTS])) {
        filters['Postal District'] = Object.keys(filterSelected[FIELD_DISTRICTS])
          .filter(district => filterSelected[FIELD_DISTRICTS][district]);
      } else if (hasAnyValue(filterSelected[FIELD_REGIONS])) {
        filters['Market Segment'] = Object.keys(filterSelected[FIELD_REGIONS])
          .filter(region => filterSelected[FIELD_REGIONS][region])
          .map(region => MARKET_SEGMENT_ID_MAP[region]);
      }
    }

    // handle property or street name search
    // if (filterSelected.id) {
    //   const chunks = filterSelected.id.split(',');
    //   filters['Project Name'] = `:eq:${chunks[0]}`;
    //   filters['Street'] = `:eq:${chunks[1]}`;
    // } else if (hasFilterSelected(FILTER_PROPERTY_INPUT, filterSelected) && filterSelected[FIELD_NAME]?.length > 2) {
    //   filters['__PROPSEARCH__'] = filterSelected[FIELD_NAME];
    // }

    // add conditionals
    const conditionals = createConditionals(filterSelected);
    if (conditionals.length > 0) filters.conditions = conditionals;

    return filters;
  };

  const createConditionals = (filterSelected) => {
    const conditionals = [];
    const currYear = getCurrentYear();
    
    // set lease
    if (hasFilterSelected(FILTER_LEASE, filterSelected)) {
      const leaseCondition = { or: [] };
      if (filterSelected[FILTER_UNCOMPLETED.field]) {
        leaseCondition.or.push({
          'Completion Date': ':n:',
        });
        leaseCondition.or.push({
          'Completion Date': ':eq:Uncompleted',
        });
      }
      const tenureType = 'tenure_years';
      const leaseField = 'tenure_start_date';
      const tenureConditions = getTenureValues(FILTER_TENURE, filterSelected).map(value => {
        // ts = curr year - tenure year + min/max

        if (value === null) {
          // include freehold - tenure start date will be null
          return {
            and: {
              [leaseField]: ':n:',
              [tenureType]: ':xlx:,Freehold,'
            }
          };
        }

        let condition = '';
        const startYear = currYear - value + (filterSelected[FILTER_LEASE.field].value.min ?? 0);
        const endYear = currYear - value + (filterSelected[FILTER_LEASE.field].value.max ?? 0);
        const startTs = timestampFromYear(startYear);
        const endTs = timestampFromYear(endYear);
        const hasMin = hasMinRangeSelected(FILTER_LEASE, filterSelected[FILTER_LEASE.field]);
        const hasMax = hasMaxRangeSelected(FILTER_LEASE, filterSelected[FILTER_LEASE.field]);
        if (hasMin && hasMax) {
          condition = `t:bt:${startTs}:${endTs}`;
        } else if (hasMin) {
          condition = `t:gte:${startTs}`;
        } else {
          condition = `t:lte:${endTs}`;
        }

        return {
          and: {
            [leaseField]: condition,
            [tenureType]: `:xlx:,${value},`
          }
        };
      });
      leaseCondition.or.push(...tenureConditions);

      conditionals.push(leaseCondition);
    }

    // set age
    if (hasFilterSelected(FILTER_AGE, filterSelected)) {
      const ageCondition = { or: [] };
      const ageField = 'tenure_start_date';
      const completionField = 'Completion Date';
      if (filterSelected[FILTER_UNCOMPLETED.field]) {
        ageCondition.or.push({
          [completionField]: ':n:'
        });
      }
      let condition = '';
      const endYear = currYear - (filterSelected[FILTER_AGE.field].value.min ?? 0);
      const startYear = currYear - (filterSelected[FILTER_AGE.field].value.max ?? 0);
      const startTs = timestampFromYear(startYear);
      const endTs = timestampFromYear(endYear);
      const hasMin = hasMinRangeSelected(FILTER_AGE, filterSelected[FILTER_AGE.field]);
      const hasMax = hasMaxRangeSelected(FILTER_AGE, filterSelected[FILTER_AGE.field]);
      if (hasMin && hasMax) {
        condition = `t:bt:${startTs}:${endTs}`;
      } else if (hasMin) {
        condition = `t:lte:${endTs}`;
      } else {
        condition = `t:gte:${startTs}`;
      }
      ageCondition.or.push({
        [ageField]: condition,
        and: {
          [ageField]: ':n:',
          [completionField]: condition,
        }
      });

      conditionals.push(ageCondition);
    }

    return conditionals;
  };

  return (
    <TableSearchPage
      user={user}
      session={session}
      metadata={metadata}
      DEFAULT_COLUMNS={DEFAULT_COLUMNS}
      REQUIRED_COLUMNS={REQUIRED_COLUMNS}
      FILTER_OPTIONS={FILTER_OPTIONS}
      GROUP_OPTIONS={GROUP_OPTIONS}
      DEFAULT_SORT_ORDER={DEFAULT_SORT_ORDER}
      fetchApi={getTransactionsTable}
      cacheUrl="https://realsmart.global.ssl.fastly.net/transactions10.json"
      pageCode="t"
      pageLabel="TRANSACTION"
      createFilter={createFilter}
      logApi={logTransactionSearchFilter}
      title="Search Transactions"
    />
  );
};

TransactionSearchPage.propTypes = {
  user: PropTypes.object,
  session: PropTypes.string.isRequired,
};

TransactionSearchPage.defaultProps = {
  user: null,
};

export default TransactionSearchPage;
