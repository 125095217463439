import { NA } from "@/utils/convert";
import {
  ICON,
  getMarkerScaleByTx,
  getPropertyBgColor,
  getPropertyIconByType,
  getPropertyMarkerColor,
  getPropertyTextColor
} from "@/utils/map";
import { checkMediaQuery } from "@/utils/user";
import { Marker } from "react-map-gl/maplibre";

const PropertyMarker = ({
  idx,
  target,
  property,
  goToMarker,
  onMouseEnter,
  onMouseLeave
}) => {
  const data = property.properties;
  const markerScale = getMarkerScaleByTx((data.totalTx ?? 0), 0.5, 1.5, 10, 3000);
  const markerColor = getPropertyMarkerColor(data, 'profitable');

  const propTypes = [...data.properties.reduce((s, p) => {
    p.types.forEach(t => s.add(t));
    return s;
  }, new Set())];

  // check if the marker on map is selected
  // any property within this marker selected will mean that this marker is selected
  // render the roof to indicate marker selected
  const isSelectedMarker = target?.id === data.name;

  return (
    <Marker
      key={`propm-${idx}`}
      latitude={data.lat}
      longitude={data.lng}
      anchor="bottom"
      onClick={e => {
        e.originalEvent.stopPropagation();
        goToMarker(data.name);
      }}
    >
      <div
        style={{
          opacity: 1,
          transform: `scale(${markerScale})`
        }}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        data-bs-toggle="offcanvas"
        data-bs-target="#propSidebar"
        data-tooltip-id="prop-tooltip"
        data-tooltip-html={checkMediaQuery() ? `<div class="map-tooltip-content"><span class="fw-500">${data.name}</span></div>` : `
          <div class="map-tooltip-content">
          <span class="fw-500">${data.name}</span>
          <div class="map-tooltip-l-content">
          Profitable %: <span class="${getPropertyTextColor(data, 'profitable')}">${data.profitable === null || data.profitable < 0 ? NA : `${data.profitable.toFixed(0)}%`}</span>
          <br />
          Total Transactions: ${data.totalTx}
          <br />
          Property Types: ${propTypes.join(', ')}
          </div>
          <span class="map-tooltip-hint">${isSelectedMarker ? 'Current property' : 'Click to view details'}</span>
          </div>
        `}
        data-tooltip-variant="dark"
        data-tooltip-place="bottom"
      >
        {isSelectedMarker && <div className={`prop-marker-roof ${getPropertyBgColor(data, 'profitable')}`}></div>}
        <svg
          height={60}
          viewBox="0 0 24 24"
          style={{
            cursor: 'pointer',
            fill: markerColor,
            stroke: 'none',
          }}
        >
          <path d={ICON} />
          <circle cx="12" cy="10" r="8" fill="#fff" />
          <image
            href={getPropertyIconByType(propTypes)}
            x="7" 
            y="5" 
            width="10"
            height="10"
          />
        </svg>
      </div>
    </Marker>
  );
};

export default PropertyMarker;
