import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSearchParams, useNavigate, useLocation, Link } from "react-router-dom";

import DefaultHeader from "@/components/header/default-header";
import TopHeaderFilter from "@/components/common/TopHeaderFilter";
import MetaComponent from "@/components/common/MetaComponent";
import Loader from "@/components/common/Loader";
import ProfitTable from "@/components/transactions/ProfitTable";
import {
  getUserHistory,
  logNameSearch,
  logPageView,
  saveUserHistory
} from "@/utils/api";
import { deepCopy } from "@/utils/convert";
import { PAGE_LIMIT_OPTIONS } from "@/utils/table";
import {
  convertToUrl, 
  formatNumberFromParam, 
} from "@/utils/url";
import {
  FIELD_NAME,
  clearFilters,
  createReadableFilter,
  createURLFilter,
  createUrlParamFromReadableFilter,
  initializeFilters,
  initializeSortOrder
} from "@/utils/filter";
import { continueIfAllowedUser, generateRefreshUrl, noSelectClass } from "@/utils/user";
import Sidebar from "@/components/common/Sidebar";
import TablePagination from "@/components/common/TablePagination";
import HistoryGroup from "@/components/common/HistoryGroup";
import TransactionTable from "@/components/transactions/TransactionTable";
import RentalTable from "@/components/rental/RentalTable";
import FilterBox from "@/components/mapv2/search/FilterBox";
import { LOCATION_PROPERTY } from "@/utils/areas";

const TableSearchPage = ({
  user,
  session,
  metadata,
  DEFAULT_COLUMNS,
  REQUIRED_COLUMNS,
  FILTER_OPTIONS,
  GROUP_OPTIONS,
  DEFAULT_SORT_ORDER,
  fetchApi,
  cacheUrl,
  pageCode,
  pageLabel,
  createFilter,
  logApi,
  title
}) => {
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const { pathname, search } = useLocation();

  // set table columns
  const [defaultColumns, setDefaultColumns] = useState(DEFAULT_COLUMNS);
  const [fullColumns, setFullColumns] = useState(DEFAULT_COLUMNS.map(col => ({ ...col, enabled: true })));
  const [columns, setColumns] = useState(DEFAULT_COLUMNS);
  const [apiColumns, setApiColumns] = useState(REQUIRED_COLUMNS);
  
  // results
  const [dataLoading, setDataLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(formatNumberFromParam(params.get('page'), 1));
  const [results, setResults] = useState([]);
  const [pageLimit, setPageLimit] = useState(PAGE_LIMIT_OPTIONS[1]);
  const [offset, setOffset] = useState(
    formatNumberFromParam(params.get('page'), 1) > 1
      ? (formatNumberFromParam(params.get('page'), 1) - 1) * pageLimit
      : formatNumberFromParam(params.get('offset'), 0)
  );
  const [hasNextPage, setHasNextPage] = useState(false);
  const [err, setErr] = useState(null);
  const [tierMsg, setTierMsg] = useState(null);
  const [tierBlocked, setTierBlocked] = useState(false);

  // filters
  const [filterSelected, setFilterSelected] = useState(initializeFilters(FILTER_OPTIONS, params));
  const [currentFilters, setCurrentFilters] = useState({});
  const [filterHistory, setFilterHistory] = useState([]);
  const [filterProject, setFilterProject] = useState(params.get('id'));

  // sorts
  const [sortOrder, setSortOrder] = useState(initializeSortOrder(params, DEFAULT_SORT_ORDER));

  // group by
  const [groupBy, setGroupBy] = useState(null);
  const [selectedGroupOption, setSelectedGroupOption] = useState({});

  const updateUrl = (offset, filter, filterSelected, sortOrder) => {
    // calculate latest offset and page
    const latestOffset = offset;
    const latestPage = (latestOffset / pageLimit) + 1;

    // handle empty filter
    const isEmptyFilter = Object.keys(filter ?? {}).length === 0;
    if (isEmptyFilter) {
      convertToUrl({}, sortOrder, latestPage, latestOffset);
      return;
    }

    // create the url filters
    const urlFilters = createURLFilter(FILTER_OPTIONS, filterSelected);

    // set the url without refreshing page
    convertToUrl(urlFilters, sortOrder, latestPage, latestOffset);
  }

  const loadData = (
    page,
    offset,
    filters,
    filterSelected,
    sortOrder = DEFAULT_SORT_ORDER,
    columns = REQUIRED_COLUMNS,
    group = null
  ) => {
    setDataLoading(true);
    setErr(null);
    setTierMsg(null);
    let sort = deepCopy(sortOrder);
    if (group) {
      Object.keys(sort).forEach(k => {
        if (k !== 'count') delete sort[k];
      });
    } else if ('count' in sort) {
      // remove 'count' in sorting if not a 'group by'
      delete sort['count'];
    }
    const apiParams = {
      limit: page,
      offset,
      filters,
      sorts: sort,
      columns,
    };
    if (group) {
      apiParams.group = group;
    }
    fetchApi(apiParams, (data) => {
      if ('tier_msg' in data) {
        setTierMsg(data['tier_msg']);
        if (data.allow === false) {
          setTierBlocked(true);
          setDataLoading(false);
          return;
        }
      }
      setHasNextPage(!!data.next_page);
      setOffset(data.offset);
      const newResults = data.results ?? [];
      setResults(newResults.map(r => ({
        ...r,
        id: `${r['Project Name']},${r[pageCode === 'r' ? 'Street Name' : 'Street']}`
      })));
      setDataLoading(false);
    }, (err) => {
      setErr(err);
      setDataLoading(false);
    });

    updateUrl(offset, filters, filterSelected, sort);
  };

  const loadCachedData = () => {
    setDataLoading(true);
    setErr(null);
    setTierMsg(null);
    fetch(cacheUrl)
    .then(resp => {
      return resp.json();
    }).then(data => {
      setHasNextPage(!!data.next_page);
      setOffset(data.offset);
      const newResults = data.results ?? [];
      setResults(newResults.map(r => ({ ...r, id: `${r['Project Name']},${r['Street'] ?? r['Street Name']}`})));
      setDataLoading(false);
    }).catch(err => {
      setErr(err);
      setDataLoading(false);
    });
  };

  const isDefaultView = (offset, filters, sortOrder, pageLimit) => offset === 0
    && Object.keys(filters).length === 0
    && JSON.stringify(sortOrder) === JSON.stringify(DEFAULT_SORT_ORDER)
    && pageLimit === PAGE_LIMIT_OPTIONS[1];

  useEffect(() => {
    continueIfAllowedUser(user, () => {
      const filters = createFilter(filterSelected);
      setCurrentFilters(filters);
      if (isDefaultView(offset, filters, sortOrder, pageLimit)) {
        loadCachedData();
        fetchApi({ m: 0 });
      } else {
        loadData(pageLimit, offset, filters, filterSelected, sortOrder, apiColumns, groupBy);
      }
      getUserHistory(pageCode, (data) => setFilterHistory(data));
      logPageView(pageLabel, session);
    }, navigate, pathname, search);
  }, [user, pageLimit]);

  const hideMobileFilter = () => {
    if (document.getElementById('listingSidebar').classList.contains('show')) {
      document.getElementById('filter-close-button').click();
    }
  };

  const logFilterAnalytics = () => {
    const filter = createReadableFilter(FILTER_OPTIONS, filterSelected);
    if (Object.keys(filter).length > 0) {
      logApi(session, filter);
    }
  };

  const saveHistory = (id) => {
    const newFilterHistory = [
      {
        ts: new Date().getTime(),
        filter: {
          ...createReadableFilter(FILTER_OPTIONS, filterSelected),
          id,
        },
        sort: sortOrder,
      },
      ...filterHistory,
    ].slice(0, 3);
    setFilterHistory(newFilterHistory);
    saveUserHistory(pageCode, newFilterHistory);
  };

  const resetPage = () => {
    setCurrentPage(1);
    setOffset(0);
  };

  const onPageChange = (page) => {
    setCurrentPage(page);
    window.scrollTo(0, 0);
    loadData(pageLimit, (page - 1) * pageLimit, currentFilters, filterSelected, sortOrder, apiColumns, groupBy);
  };

  const onResetFilter = () => {
    const newFilterSelected = clearFilters(FILTER_OPTIONS);
    setFilterSelected(newFilterSelected);
    setCurrentFilters({});
    setFilterProject(null);
    resetPage();
    loadData(pageLimit, 0, {}, newFilterSelected, sortOrder, apiColumns, groupBy);
  };

  const onApplyFilter = () => {
    window.scrollTo(0, 0);
    resetPage();
    const filters = createFilter(filterSelected);
    setCurrentFilters(filters);
    setSortOrder(DEFAULT_SORT_ORDER);
    loadData(pageLimit, 0, filters, filterSelected, DEFAULT_SORT_ORDER, apiColumns, groupBy);
    hideMobileFilter();
    logFilterAnalytics();
    saveHistory();
  };

  const resetLocations = () => {
    setFilterSelected({
      ...filterSelected,
      areas: {},
      districts: {},
      regions: {},
    })
  };

  // const onSearchLocation = (searchTerm) => {
  //   resetPage();
  //   setSortOrder(DEFAULT_SORT_ORDER);
  //   const { name, ...oldFilterSelected } = filterSelected;
  //   const newFilterSelected = {
  //     ...oldFilterSelected,
  //     id: `${searchTerm.name},${searchTerm.address}`
  //   };
  //   const filter = createFilter(newFilterSelected);
  //   setFilterSelected(newFilterSelected);
  //   setCurrentFilters(filter);
  //   loadData(pageLimit, 0, filter, newFilterSelected, DEFAULT_SORT_ORDER, apiColumns, groupBy);
  //   hideMobileFilter();
  //   logNameSearch(pageLabel, session, searchTerm.name);
  // };

  // const onSearchClick = (term) => {
  //   resetPage();
  //   setSortOrder(DEFAULT_SORT_ORDER);
  //   const { id, ...newFilterSelected } = filterSelected;
  //   const searchKey = pageCode === 'r' ? '__RENTSEARCH__' : '__PROPSEARCH__';
  //   const filters = {
  //     ...createFilter(newFilterSelected),
  //     [searchKey]: term
  //   };
  //   setFilterSelected(newFilterSelected);
  //   setCurrentFilters(filters);
  //   loadData(pageLimit, 0, filters, newFilterSelected, DEFAULT_SORT_ORDER, apiColumns, groupBy);
  //   hideMobileFilter();
  //   saveHistory();
  //   logNameSearch(pageLabel, session, term);
  // };

  const onSort = (column) => {
    const id = column.id;
    let newSortOrder = {};
    if (sortOrder[id] === null || sortOrder[id] === undefined)
      newSortOrder[id] = 'desc';
    else if (sortOrder[id] === 'desc')
      newSortOrder[id] = 'asc';
    setSortOrder(newSortOrder);
    loadData(pageLimit, 0, currentFilters, filterSelected, newSortOrder, apiColumns, groupBy);
  };

  const onGroup = (group, selectedGroupOption, columns, headers) => {
    setDataLoading(true);
    setResults([]);
    resetPage();
    setGroupBy(group);
    setSelectedGroupOption(selectedGroupOption);
    setApiColumns(columns ? columns : REQUIRED_COLUMNS);

    const finalHeaders = headers ? headers : DEFAULT_COLUMNS;
    setDefaultColumns(finalHeaders);
    setFullColumns(finalHeaders.map(col => ({ ...col, enabled: true })));
    setColumns(finalHeaders);
    
    loadData(pageLimit, 0, currentFilters, filterSelected, sortOrder, columns, group);
  };

  const updateFilterHistory = (newFilterHistory) => {
    setFilterHistory(newFilterHistory);
    saveUserHistory(pageCode, newFilterHistory);
  };

  const selectSearch = (search) => {
    const newFilterSelected = initializeFilters(FILTER_OPTIONS, createUrlParamFromReadableFilter(search.filter));
    setFilterSelected(newFilterSelected);
    resetPage();
    setSortOrder(search.sort);
    const filters = createFilter(newFilterSelected);
    if (newFilterSelected[FIELD_NAME]?.length >= 3) {
      filters['__PROPSEARCH__'] = newFilterSelected[FIELD_NAME];
    }
    if (search.filter.id) {
      filters['Project Name'] = `:eq:${search.filter.id}`;
      newFilterSelected.id = search.filter.id;
    }
    setCurrentFilters(filters);
    loadData(pageLimit, 0, filters, newFilterSelected, search.sort, apiColumns, groupBy);
  };

  const getSideBar = () => (
    <Sidebar
      // onSearchLocation={onSearchLocation}
      // onSearchClick={onSearchClick}
      resetLocations={resetLocations}
      filters={filterSelected}
      setFilters={setFilterSelected}
      options={FILTER_OPTIONS}
    />
  );

  const onSearch = (prop) => {
    const term = prop.names[0]; // for now just use the project name only
    resetPage();
    setSortOrder(DEFAULT_SORT_ORDER);
    const { id, ...newFilterSelected } = filterSelected;
    // const searchKey = pageCode === 'r' ? '__RENTSEARCH__' : '__PROPSEARCH__';
    const filters = {
      ...createFilter(newFilterSelected),
      ['Project Name']: `:eq:${term}`
    };
    newFilterSelected.id = term;
    setFilterSelected(newFilterSelected);
    setCurrentFilters(filters);
    setFilterProject(term);
    loadData(pageLimit, 0, filters, newFilterSelected, DEFAULT_SORT_ORDER, apiColumns, groupBy);
    hideMobileFilter();
    saveHistory(term);
    logNameSearch(pageLabel, session, term);
  };

  return (
    <>
      <MetaComponent meta={metadata} />
      <div className="header-margin"></div>
      <DefaultHeader user={user} />

      {!dataLoading && !err
        && <div className="py-10 bg-dark-2">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <FilterBox
                  onSearch={onSearch}
                  filterType={LOCATION_PROPERTY}
                  hint="Enter project, street, postal code"
                  style={{
                    zIndex: '10'
                  }}
                  selectedLocation={filterProject}
                  onClearSelectedLocation={() => {
                    setFilterProject(null);
                    onResetFilter();
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      }

      {err
        && <section className="layout-pt-sm watermark">
          <div className="container">
            <div className="alert alert-danger" role="alert">
              {err}
            </div>
          </div>
        </section>
      }

      {tierMsg !== null && tierMsg !== -1
        && <section className="layout-pt-sm watermark">
          <div className="alert alert-warning ml-20 mr-20" role="alert">
            You have {tierMsg ?? 0} free search{tierMsg > 1 ? 'es' : ''} remaining as a free user. <strong><u><a href="/pricing">Become a PRO user</a></u></strong> to enjoy unlimited search{tierMsg > 1 ? 'es' : ''}!
            Click <strong><u><Link className="cursor-pointer" to={generateRefreshUrl(pathname, search)}>here</Link></u></strong> if you are already a PRO user
          </div>
        </section>
      }

      <section className={`${noSelectClass(user)} watermark`}>
        <div className="container maxw">

          <div className="row y-gap-10">
            <div
              className="offcanvas offcanvas-start filter-bar-nopad"
              tabIndex="-1"
              id="listingSidebar"
            >
              <div className="offcanvas-header">
                <h5 className="offcanvas-title" id="offcanvasLabel">
                  {title}
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                ></button>
              </div>

              <div className="offcanvas-body">
                <aside className="sidebar y-gap-40 xl:d-block">
                  {getSideBar()}
                </aside>
              </div>

              <div className="row ml-10 mr-10 mt-10">
                <div className="col-3">
                  <button
                    className="button -dark-1 py-15 px-40 h-50 col-12 rounded-0 bg-red-1 text-white w-100"
                    onClick={onResetFilter}
                  >
                    Reset
                  </button>
                </div>
                <div className="col-9">
                  <button
                    className="button -dark-1 py-15 px-40 h-full col-12 rounded-0 bg-blue-1 text-white w-100"
                    onClick={onApplyFilter}
                  >
                    Apply Filter
                  </button>
                </div>
              </div>

            </div>

            <div className="col-xl-12" id="tx-table-view">
              {dataLoading
                && <div className="loader-container">
                  <Loader />
                </div>
              }

              {!dataLoading && !err
                && <>
                  {!tierBlocked
                    && <TopHeaderFilter
                      results={results}
                      showSort={[]}
                      sortOrder={sortOrder}
                      setSortOrder={setSortOrder}
                    />
                  }

                  <HistoryGroup
                    options={FILTER_OPTIONS}
                    searches={filterHistory}
                    setSearches={updateFilterHistory}
                    selectSearch={selectSearch}
                  />

                  <div className="mt-10"></div>

                  <div className="row data-table">
                    {pageCode === 'p' && 
                      <ProfitTable
                        results={results}
                        pageLimit={pageLimit}
                        setPageLimit={setPageLimit}
                        customSort={sortOrder}
                        onCustomSort={onSort}
                        defaultColumns={defaultColumns}
                        fullColumns={fullColumns}
                        setFullColumns={setFullColumns}
                        columns={columns}
                        setColumns={setColumns}
                        groupOptions={GROUP_OPTIONS}
                        originalColumns={DEFAULT_COLUMNS}
                        selectedGroupOption={selectedGroupOption}
                        setSelectedGroupOption={onGroup}
                        showHistory={filterHistory.length > 0}
                      />
                    }
                    {pageCode === 't' &&
                      <TransactionTable
                        results={results}
                        pageLimit={pageLimit}
                        setPageLimit={setPageLimit}
                        customSort={sortOrder}
                        onCustomSort={onSort}
                        defaultColumns={defaultColumns}
                        fullColumns={fullColumns}
                        setFullColumns={setFullColumns}
                        columns={columns}
                        setColumns={setColumns}
                        groupOptions={GROUP_OPTIONS}
                        originalColumns={DEFAULT_COLUMNS}
                        selectedGroupOption={selectedGroupOption}
                        setSelectedGroupOption={onGroup}
                        showHistory={filterHistory.length > 0}
                      />
                    }
                    {pageCode === 'r' &&
                      <RentalTable
                        results={results}
                        pageLimit={pageLimit}
                        setPageLimit={setPageLimit}
                        customSort={sortOrder}
                        onCustomSort={onSort}
                        defaultColumns={defaultColumns}
                        fullColumns={fullColumns}
                        setFullColumns={setFullColumns}
                        columns={columns}
                        setColumns={setColumns}
                        groupOptions={GROUP_OPTIONS}
                        originalColumns={DEFAULT_COLUMNS}
                        selectedGroupOption={selectedGroupOption}
                        setSelectedGroupOption={onGroup}
                        showHistory={filterHistory.length > 0}
                      />
                    }
                  </div>
                  {!tierBlocked
                    && <TablePagination
                      currentPage={currentPage}
                      onPageChange={onPageChange}
                      hasNextPage={hasNextPage}
                    />
                  }
                </>
              }

            </div>
          </div>
        </div>
      </section>

      {/* {hasAnyFilterSelected(FILTER_OPTIONS, filterSelected)
        && <FilterApply
          onReset={onResetFilter}
          onApplyFilter={onApplyFilter}
        />
      } */}
    </>
  );
};

TableSearchPage.propTypes = {
  user: PropTypes.object,
  session: PropTypes.string.isRequired,
};

TableSearchPage.defaultProps = {
  user: null,
};

export default TableSearchPage;
