import Loader from "@/components/common/Loader";
import { searchAmenities } from "@/utils/api";
import { LOCATION_BLOCK, LOCATION_STATION } from "@/utils/areas";
import { calculateDist, formatDistanceLabel } from "@/utils/convert";
import { useEffect, useState } from "react";

const NearbyStations = ({
  blocks,
  stations,
  focus,
  setFocus,
  isMaximized
}) => {
  const isXl = window.matchMedia("(min-width: 1200px)").matches;

  const lists = [
    'MRT Station',
    'LRT Station'
  ].map(label => ({
    title: label + 's',
    list: stations
      .filter(s => s.name.includes(label.toUpperCase()))
      .map(s => ({
        ...s,
        blocks: blocks
          .map(b => ({
            ...b,
            distance: calculateDist(s.lat, s.lng, b.lat, b.lng),
            type: LOCATION_BLOCK
          }))
          .filter(b => b.distance <= 1000)
          .sort((a, b) => a.distance - b.distance),
      }))
  }));

  const hasSelectedStation = (station) => {
    if (hasNoSelection) return;
    return focus.target?.name === station.name;
  };

  const hasSelectedBlock = (block) => {
    if (hasNoSelection) return;
    return !!focus.links?.find(l => l.postal === block.postal);
  };

  const isFocusBlock = (block) => focus?.target?.id === block.block;

  const getBlockStations = (block) => {
    const stations = [];
    results.forEach(l => l.list.forEach(s => {
      if (s.blocks.filter(b => b.address === block.address).length > 0) {
        stations.push(s);
      }
    }));
    return stations;
  };

  const hasAnyBlockSelected = (blocks) => {
    if (hasNoSelection) return;
    return blocks.some(b => focus?.target?.block === b.block);
  };

  const hasNoSelection = !focus || focus.use !== 'nearby-stn' || focus.links?.length === 0;

  return (
    <div className="pt-10 mb-50">
      {stations.length === 0
        && <div className="py-20 text-14 fw-500 text-center">No stations nearby</div>
      }

      {lists.filter(l => l.list?.length > 0).map(l => (
        <div className="row mt-20 mb-10">
          <div className="d-flex items-center">
            <i className="icon-compass text-20 mr-10"></i>
            <div className="text-20 fw-500">{l.title}</div>
          </div>

          {l.list.map((subsection) => (
            <div className={isXl && isMaximized ? 'col-4' : 'col-lg-6 col-md-12'}>
              <div className="">
                <div className="mb-20 md:mb-10 mt-20">
                  <div
                    className="d-flex items-center mb-10 hover-blue-row cursor-pointer"
                    onClick={() => {
                      if (hasSelectedStation(subsection)) {
                        setFocus(null);
                      } else {
                        setFocus({
                          use: 'nearby-stn',
                          target: { ...subsection, id: subsection.name },
                          mode: LOCATION_STATION,
                          links: subsection.blocks
                        });
                      }
                    }}
                  >
                    <div
                      className="ml-10 text-14 fw-500"
                      style={{
                        opacity: hasNoSelection
                          || hasSelectedStation(subsection)
                          || (focus?.mode === LOCATION_BLOCK && hasAnyBlockSelected(subsection.blocks))
                        ? 1 : 0.2
                      }}
                    >
                      {subsection.name}
                    </div>
                  </div>

                  <div
                    className={`row y-gap-10 x-gap-0 px-10 ${
                      isXl ? 'surrounding-fh' : ''
                    }`}
                  >
                    {subsection.blocks.map((b, i) => (
                      <div
                        className="col-12 border-top-light"
                        key={i}
                        style={{
                          opacity: hasNoSelection
                            || (hasSelectedStation(subsection) && hasSelectedBlock(b))
                            || isFocusBlock(b)
                          ? 1 : 0.2
                        }}
                      >
                        <div
                          className="row items-center justify-between"
                        >
                          <div className="col-9">
                            <div
                              className="text-13 hover-blue-row cursor-pointer"
                              onClick={() => {
                                if (isFocusBlock(b)) {
                                  setFocus(null);
                                } else {
                                  setFocus({
                                    use: 'nearby-stn',
                                    target: { ...b, id: b.address },
                                    mode: LOCATION_STATION,
                                    links: getBlockStations(b)
                                  });
                                }
                              }}
                            >
                              {b.block} {b.address} <span className="text-blue-1 pl-10">{b.postal}</span>
                            </div>
                          </div>

                          <div className="col-3">
                            <div className="text-13 text-right">
                              {formatDistanceLabel(b.distance > 1000 ? 1000 : b.distance)}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>

                </div>
              </div>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default NearbyStations;
