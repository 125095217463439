import { getJsonFile } from "@/utils/api";
import {
  decompressGroupCompData,
  getPropertyFileName
} from "@/utils/map";
import { useEffect, useState } from "react";
import CompareView from "./CompareView";

const CompareTab = ({
  user,
  data,
  target,
  screenDim,
  isMaximized,
  goToProperty,
  setFocusOn,
  compareList,
  setCompareList,
  viewComparePro,
  getProjectLabel
}) => {
  const [loading, setLoading] = useState(true);
  const [compData, setCompData] = useState({ p: {}, t: {} });

  const loadGroupData = () => {
    setLoading(true);
    getJsonFile(`c/${getPropertyFileName(target.id)}`, target.projectId, json => {
      setCompData(decompressGroupCompData(json));
      setLoading(false);
    }, err => {
      setLoading(false);
    });
  };

  useEffect(() => {
    loadGroupData();
  }, [target]);

  return (
    <CompareView
      user={user}
      data={data}
      target={target}
      loading={loading}
      setLoading={setLoading}
      compData={compData}
      setCompData={setCompData}
      screenDim={screenDim}
      isMaximized={isMaximized}
      goToProperty={goToProperty}
      setFocusOn={setFocusOn}
      compareList={compareList}
      setCompareList={setCompareList}
      viewComparePro={viewComparePro}
      getProjectLabel={getProjectLabel}
    />
  );
};

export default CompareTab;
