import { checkMediaQuery, noSelectClass } from "@/utils/user";
import ScrollToTop from "../project/ScrollToTop";
import AmenityTable from "./property/Table/AmenityTable";
import { useEffect, useState } from "react";
import { calculateDist } from "@/utils/convert";
import { getFile } from "@/utils/api";
import { decompressAmenityData } from "@/utils/map";
import Loader from "../common/Loader";
import { LOCATION_SCHOOL, getBlockUnitFromAddress } from "@/utils/areas";

const AmenityView = ({
  user,
  target,
  isMinimized,
  isMaximized,
  handleMaximize,
  handleExpand,
  closePropertyDetails,
  goToProperty,
  goToBlock
}) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    getFile(target.type === LOCATION_SCHOOL ? 's' : 't', target.id, txt => {
      const data = decompressAmenityData(target.type, txt);

      // calculate distance if not provided in the data
      // also do a filter again just to make sure no anomalous distance exceeding limit is displayed
      setData(
        data
          .map(r => ({
            ...r,
            distance: r.distance !== undefined
              ? r.distance
              : calculateDist(target.lat, target.lng, r.lat, r.lng)
          }))
          .filter(r => r.distance <= 1000)
          .sort((a, b) => a.distance - b.distance)
      );

      setLoading(false);
    }, err => {
      setLoading(false);
    });
  }, [target]);

  const mediaMatches = checkMediaQuery();

  const goToPropertyFromRow = (data) => {
    const idx = data.cell.row.index;
    goToProperty(data.data[idx].marker, data.data[idx].projectId);
  };

  const goToBlockFromRow = (data) => {
    const idx = data.cell.row.index;
    let block = getBlockUnitFromAddress(data.data[idx].address);
    const match = block.block.match(/^\S+/);
    block = match ? match[0] : block.block;
    goToBlock({
      ...data.data[idx],
      block,
    });
  };

  return (
    <>
      {loading
        && <div className="loader-container">
          <Loader />
        </div>
      }

      {!isMinimized && !loading && (!data || data.length === 0) &&
        <div className={`modal-container ${
          isMaximized ? "modal-full modal-container-fixed" : (
            mediaMatches ? "" : "modal-container-fixed"
          )
        }`}>

          {mediaMatches
            && <div className="d-flex justify-content-between text-10 text-light-1">
              <div className="p-2 py-0"></div>
              <div
                className={`p-2 py-0 mb-10 cursor-pointer ${
                  isMaximized ? '' : 'rotate-180'
                }`}
                onClick={() => isMaximized ? handleExpand() : handleMaximize()}
              >
                <i className="icon-chevron-sm-down py-5" />
              </div>
              <div
                className="p-2 pt-5 pb-0 cursor-pointer"
                onClick={() => closePropertyDetails()}
              >
                <i className="icon-close py-5" />
              </div>
            </div>
          }

          {!mediaMatches
            && <div className="d-flex justify-content-end text-10 text-light-1">
              <div
                className="p-2 px-10 pt-5 pb-0 cursor-pointer"
                onClick={() => closePropertyDetails()}
              >
                <i className="icon-close" />
              </div>
            </div>
          }

          <div className="modal-body watermark d-flex">
            {!mediaMatches
              && <div
                className="p-2 modal-expand button text-10 px-0 d-flex align-items-center cursor-pointer"
                onClick={() => isMaximized ? handleExpand() : handleMaximize()}
              >
                <div className={isMaximized ? 'rotate-270' : 'rotate-90'}>
                  <i className="icon-chevron-sm-down px-5" />
                </div>
              </div>
            }

            <div
              id="project-details-modal"
              className={`p-2 modal-scoll-content text-center fw-500 py-40 ${
                isMaximized ? 'modal-scroll-am-full' : (mediaMatches ? 'modal-scroll-am' : 'modal-scoll-content-am')
              }`}
            >
              No data found
            </div>
          </div>
        </div>
      }

      {!isMinimized && !loading && (
        <div className={`modal-container ${
          isMaximized ? "modal-full modal-container-fixed" : (
            mediaMatches ? "" : "modal-container-fixed"
          )
        }`}>

          {mediaMatches
            && <div className="d-flex justify-content-between text-10 text-light-1">
              <div className="p-2 py-0"></div>
              <div
                className={`p-2 py-0 mb-10 cursor-pointer ${
                  isMaximized ? '' : 'rotate-180'
                }`}
                onClick={() => isMaximized ? handleExpand() : handleMaximize()}
              >
                <i className="icon-chevron-sm-down py-5" />
              </div>
              <div
                className="p-2 pt-5 pb-0 cursor-pointer"
                onClick={() => closePropertyDetails()}
              >
                <i className="icon-close py-5" />
              </div>
            </div>
          }

          {!mediaMatches
            && <div className="d-flex justify-content-end text-10 text-light-1">
              <div
                className="p-2 px-10 pt-5 pb-0 cursor-pointer"
                onClick={() => closePropertyDetails()}
              >
                <i className="icon-close" />
              </div>
            </div>
          }

          <div className="modal-body watermark d-flex">
            {!mediaMatches
              && <div
                className="p-2 modal-expand button text-10 px-0 d-flex align-items-center cursor-pointer"
                onClick={() => isMaximized ? handleExpand() : handleMaximize()}
              >
                <div className={isMaximized ? 'rotate-270' : 'rotate-90'}>
                  <i className="icon-chevron-sm-down px-5" />
                </div>
              </div>
            }

            <div
              id="project-details-modal"
              className={`p-2 modal-scoll-content ${
                isMaximized ? 'modal-scroll-am-full' : (mediaMatches ? 'modal-scroll-am' : 'modal-scoll-content-am')
              }`}
            >

              <ScrollToTop />
            
              <section className={`modal-table${isMaximized ? '-full' : ''}`}>
                <div className={`data-table ${noSelectClass(user)}`}>
                  <div className="d-flex noselect">
                    <i className="p-2 icon-location-2 text-18" />
                    <h3 className="p-2 text-15 fw-500">{target.name ?? target.names?.join(', ')}</h3>
                  </div>
                  <AmenityTable
                    data={data}
                    goToProperty={goToPropertyFromRow}
                    goToBlock={goToBlockFromRow}
                  />
                </div>
              </section>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AmenityView;
