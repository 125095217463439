import { LOCATION_BLOCK, LOCATION_SCHOOL } from "@/utils/areas";
import { calculateDist, deepCopy, formatDistanceLabel } from "@/utils/convert";

const NearbySchools = ({
  blocks,
  schools,
  focus,
  setFocus,
  isMaximized
}) => {
  const isXl = window.matchMedia("(min-width: 1200px)").matches;

  const lists = [
    {
      title: 'Primary Schools within 1km',
      list: schools.map(s => ({
        ...s,
        blocks: blocks
          .map(b => ({
            ...b,
            distance: calculateDist(s.lat, s.lng, b.lat, b.lng),
            type: LOCATION_BLOCK
          }))
          .filter(b => b.distance <= 1000)
          .sort((a, b) => a.distance - b.distance),
      }))
    },
  ];

  const hasSelectedSchool = (school) => {
    if (hasNoSelection) return;
    return focus.target?.name === school.name;
  };

  const hasSelectedBlock = (block) => {
    if (hasNoSelection) return;
    return !!focus.links?.find(l => l.postal === block.postal);
  };

  const isFocusBlock = (block) => focus?.target?.id === block.block;

  const getBlockSchools = (block) => {
    const schools = [];
    lists.forEach(l => l.list.forEach(s => {
      if (s.blocks.filter(b => b.postal === block.postal).length > 0) {
        schools.push(s);
      }
    }));
    return schools;
  };

  const hasAnyBlockSelected = (blocks) => {
    if (hasNoSelection) return;
    return blocks.some(b => focus?.target?.block === b.block);
  };

  const hasNoSelection = !focus || focus.use !== 'nearby-sch' || focus.links?.length === 0;

  return (
    <div className="pt-10 mb-50">
      {schools.length === 0
        && <div className="py-20 text-14 fw-500 text-center">No schools nearby</div>
      }

      {lists.filter(l => l.list?.length > 0).map(l => (
        <div className="row mt-20 mb-10">
          <div className="d-flex items-center">
            <i className="icon-compass text-20 mr-10"></i>
            <div className="text-20 fw-500">{l.title}</div>
          </div>

          {l.list.map((subsection) => (
            <div className={isXl && isMaximized ? 'col-4' : 'col-lg-6 col-md-12'}>
              <div className="">
                <div className="mb-20 md:mb-10 mt-20">
                  <div
                    className="d-flex items-center mb-10 hover-blue-row cursor-pointer"
                    onClick={() => {
                      if (hasSelectedSchool(subsection)) {
                        setFocus(null);
                      } else {
                        setFocus({
                          use: 'nearby-sch',
                          target: { ...subsection, id: subsection.postal },
                          mode: LOCATION_SCHOOL,
                          links: subsection.blocks
                        });
                      }
                    }}
                  >
                    <div
                      className="ml-10 text-14 fw-500"
                      style={{
                        opacity: hasNoSelection
                          || hasSelectedSchool(subsection)
                          || (focus?.mode === LOCATION_BLOCK && hasAnyBlockSelected(subsection.blocks))
                        ? 1 : 0.2
                      }}
                    >
                      {subsection.name}
                    </div>
                  </div>

                  <div
                    className={`row y-gap-10 x-gap-0 px-10 ${
                      isXl ? 'surrounding-fh' : ''
                    }`}
                  >
                    {subsection.blocks.map((b, i) => (
                      <div
                        className="col-12 border-top-light"
                        key={i}
                        style={{
                          opacity: hasNoSelection
                            || (hasSelectedSchool(subsection) && hasSelectedBlock(b))
                            || isFocusBlock(b)
                          ? 1 : 0.2
                        }}
                      >
                        <div
                          className="row items-center justify-between"
                        >
                          <div className="col-9">
                            <div
                              className="text-13 hover-blue-row cursor-pointer"
                              onClick={() => {
                                if (isFocusBlock(b)) {
                                  setFocus(null);
                                } else {
                                  setFocus({
                                    use: 'nearby-sch',
                                    target: { ...b, id: b.block },
                                    mode: LOCATION_BLOCK,
                                    links: getBlockSchools(b)
                                  });
                                }
                              }}
                            >
                              {b.block} {b.address} <span className="text-blue-1 pl-10">{b.postal}</span>
                            </div>
                          </div>

                          <div className="col-3">
                            <div className="text-13 text-right">
                              {formatDistanceLabel(b.distance > 1000 ? 1000 : b.distance)}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>

                </div>
              </div>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default NearbySchools;
