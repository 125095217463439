import { noSelectClass } from "@/utils/user";
import PerformancePanel from "./Overview/PerformancePanel";
import PropertyMap from "./Overview/PropertyMap";
import Factsheet from "./Overview/Factsheet";
import PropertyButtons from "./Overview/PropertyButtons";
import { useCallback, useEffect, useState } from "react";
import { deepCopy } from "@/utils/convert";
import { getPropertyTextColor } from "@/utils/map";
import { LOCATION_PROPERTY } from "@/utils/areas";

const PROPERTY_BUTTONS = [
  {
    id: 'compare',
    label: 'Add to Compare PRO',
    altLabel: 'Remove from Compare PRO',
    icon: 'icon-plus'
  },
  // {
  //   id: 'subscribe',
  //   label: 'Susbcribe',
  //   icon: 'icon-email-2'
  // },
  // {
  //   id: 'like',
  //   label: 'Like',
  //   icon: 'icon-like'
  // },
  {
    id: 'share',
    label: 'Share',
    icon: 'icon-share'
  }
];

const OverviewTab = ({
  user,
  data,
  target,
  marker,
  isMaximized,
  screenDim,
  compareList,
  setCompareList,
  addToCompareList,
  goToProperty
}) => {
  const targetCompKey = target && target.type === LOCATION_PROPERTY ? `${target.id}_${target.projectId}` : '';

  const [selection, setSelection] = useState({
    compare: targetCompKey in compareList
  });

  const [selectedImg, setSelectedImg] = useState(null);
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);
  const minSwipeDistance = 50;

  const chartColGrid = (screenDim.width > screenDim.height)
    && (isMaximized ? screenDim.width > 1200 : screenDim.width > 1600) ? 6 : 12;

  const onTouchStart = (e) => {
    setTouchEnd(null);
    setTouchStart(e.targetTouches[0].clientX);
  }
  
  const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX);
  
  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;
    if (isLeftSwipe) setSelectedImg((selectedImg + 1) % data.gallery.length);
    if (isRightSwipe) setSelectedImg((selectedImg - 1) % data.gallery.length);
  }

  const keyPressed = useCallback(e => {
    if (selectedImg === null) return;
    e.preventDefault();
    if (e.key === 'ArrowLeft') setSelectedImg((selectedImg - 1) < 0 ? (data.gallery.length - 1) : (selectedImg - 1));
    if (e.key === 'ArrowRight') setSelectedImg((selectedImg + 1) % data.gallery.length);
  }, [selectedImg]);

  useEffect(() => {
    document.addEventListener("keydown", keyPressed, false);
    return () => {
      document.removeEventListener("keydown", keyPressed, false);
    };
  }, [keyPressed]);

  const getStreetsLabel = (streets) => streets?.length > 3 ? (
    <div
      className="d-flex items-center text-12 text-light-1 cursor-pointer"
      data-tooltip-id="map-tooltip"
      data-tooltip-html={`<span class="text-10">${data.streets.map((s, i) => i % 5 === 4 ? `${s},<br />` : (i !== data.streets.length - 1 ? `${s}, `: s)).join('')}</span>`}
      data-tooltip-variant="dark"
      data-tooltip-place="bottom"
    >
      <i className="icon-location-2 text-14 mr-5" />
      {data.streets.slice(0, 3).join(', ')} +{data.streets.length - 3} more
    </div>
  ) : (
    <div className="d-flex items-center text-12 text-light-1">
      <i className="icon-location-2 text-14 mr-5" />
      {data.streets?.join(', ')}
    </div>
  );

  const getTenuresLabel = (tenures) => tenures?.length > 3 ? (
    <div className="col-auto d-flex align-content-start flex-wrap py-0 px-0">
      {data.tenures?.slice(0, 3).map(tenure => (
        <div className="p-2 d-flex items-center text-10 text-white proj-tag px-10 rounded-100 fw-500 mr-5 mt-5 mb-5">
          {tenure}
        </div>
      ))}
      <div
        className="p-2 d-flex items-center text-10 text-white proj-tag px-10 rounded-100 fw-500 mr-5 mt-5 mb-5 cursor-pointer"
        data-tooltip-id="map-tooltip"
        data-tooltip-html={`<span class="text-10">${data.tenures.slice(3).join('<br />')}</span>`}
        data-tooltip-variant="dark"
        data-tooltip-place="bottom"
      >
        +{data.tenures.length - 3} more
      </div>
    </div>
  ) : (
    <div className="col-auto d-flex align-content-start flex-wrap py-0">
      {data.tenures?.map(tenure => (
        <div className="p-2 d-flex items-center text-10 text-white proj-tag px-10 rounded-100 fw-500 mr-10 mt-5 mb-5">
          {tenure}
        </div>
      ))}
    </div>
  );

  const onSelectButton = (option) => {
    if (option === 'share') {
      if (window.navigator.share) {
        window.navigator.share({
          title: `REALSMART.SG - ${data.name}`,
          text: `I think you'll be interested in this property - ${data.name}. Brought to you by REALSMART.SG`,
          url: `https://realsmart.sg/map?id=${encodeURIComponent(target.id)}&p=${encodeURIComponent(target.projectId)}&r=${user?.claims?.user_id ?? '0'}`,
        });
      } else {
        // TODO fallback view
      }
    } else if (option === 'compare') {
      if (selection.compare) {
        const newCompareList = deepCopy(compareList);
        delete newCompareList[targetCompKey];
        setCompareList(newCompareList);
        setSelection({...selection, compare: false});
      } else {
        addToCompareList(targetCompKey);
        setSelection({...selection, compare: true});
      }
    }
  };

  return (
    <>
      <section className="pt-0" id="reviews">
        <div className={`px-5 ${noSelectClass(user)}`}>
          <div className="row y-gap-10 justify-between">
            <div className="col-12 row x-gap-50 y-gap-10 py-0">
              <div className="row justify-between items-end">
                <div className="col-auto">
                  <div className="row x-gap-20  items-center">
                    <div className="col-auto">
                      <h1 className="text-16 sm:text-20 fw-600 mt-10">
                        {/* {data.name} */}

                        <div className="dropdown js-dropdown js-category-active heatmap-selector-menu map-mode-selector mb-10">
                          <div
                            className="dropdown__button d-flex items-center align-items-center bg-white border-light rounded-3 px-15 py-10 text-14 lh-12"
                            data-bs-toggle="dropdown"
                            data-bs-auto-close="true"
                            aria-expanded="false"
                            data-bs-offset="0,10"
                          >
                            <span className="js-dropdown-title">
                              <span className="js-dropdown-pri">{data.name}</span>
                            </span>
                            <i className="icon icon-chevron-sm-down text-7 ml-10" />
                          </div>
                          <div className="toggle-element -dropdown dropdown-menu" style={{ minWidth: '200px' }}>
                            <div className="text-13 y-gap-15 js-dropdown-list">
                              {marker.properties.sort((a, b) => a.lastTxDate > b.lastTxDate).map(p => (
                                <div key={p.projectId}>
                                  <button
                                    className={`d-block js-dropdown-link ${
                                      data.projectId === p.projectId ? "text-blue-1 " : ""
                                    }`}
                                    onClick={() => {
                                      goToProperty(p.marker, p.projectId);
                                      document.querySelector(".js-dropdown-pri").textContent = p.project;
                                    }}
                                  >
                                    {p.project} {p.profitable !== null && data.projectId !== p.projectId
                                      ? <span className={`${getPropertyTextColor(p, 'profitable')}`}>&nbsp;{`${p.profitable.toFixed(0)}%`}</span>
                                      : ''
                                    }
                                  </button>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </h1>
                    </div>
                  </div>
                  <div className="row x-gap-20 y-gap-0 items-center">
                    <div className="col-auto">
                      {getStreetsLabel(data.streets)}
                    </div>
                    {getTenuresLabel(data.tenures)}
                  </div>
                </div>
              </div>
              
            </div>

            <div className="col-12 pt-10">
              <PropertyButtons
                options={PROPERTY_BUTTONS}
                selection={selection}
                onSelect={onSelectButton}
              />
            </div>

            <div className={`col-${chartColGrid} text-blue-1`}>
              <PerformancePanel data={data} />
            </div>

            {chartColGrid === 6
              && <div className={`col-${chartColGrid} text-blue-1`}>
                <div className="mt-5" style={{ height: data.score !== null ? 173 : 201 } }>
                  <PropertyMap data={data} zoom={8.8} />
                </div>
              </div>
            }

            {chartColGrid !== 6
              && <div className="col-12">
                <div className="proj-gallery">
                  <PropertyMap data={data} />
                </div>
              </div>
            }

            {data?.gallery?.length > 0
              && <div className="col-12">
                <div className="photo-gallery d-flex">
                  {data.gallery.map((d, i) => (
                    <img
                      loading="lazy"
                      src={d.url}
                      onClick={() => setSelectedImg(i)}
                    />
                  ))}
                </div>
              </div>
            }

            {selectedImg !== null
              &&  <div
                style={{
                  position: 'fixed',
                  top: 60,
                  left: 0,
                  width: '100%',
                  height: 'calc(100svh - 60px)',
                  backgroundColor: 'rgba(0, 0, 0, 0.8)',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  zIndex: 1000,
                  cursor: 'pointer'
                }}
                onClick={() => setSelectedImg(null)}
                onTouchStart={onTouchStart}
                onTouchMove={onTouchMove}
                onTouchEnd={onTouchEnd}
              >
                <button
                  onClick={() => setSelectedImg(null)}
                  style={{
                    position: 'absolute',
                    top: '20px',
                    right: '20px',
                    backgroundColor: 'transparent',
                    color: 'white',
                    border: 'none',
                    fontSize: '24px',
                    cursor: 'pointer',
                  }}
                >
                  <i className="icon-close" />
                </button>

                <button
                  onClick={e => {
                    e.stopPropagation();
                    setSelectedImg((selectedImg - 1) % data.gallery.length);
                  }}
                  style={{
                    position: 'absolute',
                    bottom: '20px',
                    left: '20px',
                    backgroundColor: 'transparent',
                    color: 'white',
                    border: 'none',
                    fontSize: '24px',
                    cursor: 'pointer',
                  }}
                >
                  <i className="icon-chevron-left" />
                </button>

                <button
                  onClick={e => {
                    e.stopPropagation();
                    setSelectedImg((selectedImg + 1) % data.gallery.length);
                  }}
                  style={{
                    position: 'absolute',
                    bottom: '20px',
                    right: '20px',
                    backgroundColor: 'transparent',
                    color: 'white',
                    border: 'none',
                    fontSize: '24px',
                    cursor: 'pointer',
                  }}
                >
                  <i className="icon-chevron-right" />
                </button>

                <img
                  src={data.gallery[selectedImg].fullsize}
                  style={{
                    maxHeight: '90%',
                    maxWidth: '90%',
                    cursor: 'default'
                  }}
                  onClick={e => e.stopPropagation()}
                />
              </div>
            }

            <Factsheet data={data} isMaximized={isMaximized} screenDim={screenDim} />
          </div>
        </div>
      </section>
    </>
  );
};

export default OverviewTab;
